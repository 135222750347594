import { setCookie, getCookieValue, getCookieValues } from '@tcc/shared/src/helpers/cookie';
import { getISODate } from '@tcc/shared/src/helpers/date';
import { stringify } from '@tcc/shared/src/helpers/object';
import { createGuid } from '@tcc/shared/src/helpers/guid';

import VisitHelper from '@tcc/shared/src/helpers/visitHelper';

class VisitHelperTcc extends VisitHelper {
  saveSessionCookie(visitValues) {
    const fbSessionCookieVal = stringify(visitValues, '&', '=');
    setCookie('fb_sessiontraffic', fbSessionCookieVal, 20, '/');
  }

  setPageCount(pageCount) {
    const visitValues = getCookieValues('fb_sessiontraffic');
    visitValues.pc = pageCount.toString();
    visitValues.C_TOUCH = getISODate();
    this.saveSessionCookie(visitValues);
  }

  getVisitInfo() {
    let visitorGuid = getCookieValue('visitor', 'vid');
    const visitValues = getCookieValues('fb_sessiontraffic');
    let visitGuid = visitValues.pathway;
    let pageCount = parseInt(visitValues.pc, 10) || 0;

    if (typeof (visitGuid) === 'undefined') {
      visitGuid = createGuid();
      pageCount = 0;

      const currentDate = getISODate();
      const fbSessionTraffic = {
        C_TOUCH: currentDate,
        pathway: visitGuid,
        V_DATE: currentDate,
        pc: pageCount
      };

      setCookie('pathway', visitGuid, 20, '/');
      this.saveSessionCookie(fbSessionTraffic);
    }
    if (typeof (visitorGuid) === 'undefined') {
      visitorGuid = visitGuid;

      const cookieVal = `vid=${visitorGuid}`;
      setCookie('visitor', cookieVal, 525600, '/');
    }
    return {
      visitorGuid,
      visitGuid,
      pageCount
    };
  }
}

export default VisitHelperTcc;
