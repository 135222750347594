import { SchemaDefinitionBlock } from './schemaDefinitionBlocks';

class SchemaHelper {

  constructor(commandSchemaDefinitions) {
    this.commandSchemaDefinitions = commandSchemaDefinitions;
  }

  handleSchema(name, type, version, inputData, internal, sinksOverride) {
    // An exception will be thrown if the schema does not exist
    const schemaDefinition = new SchemaDefinitionBlock(
      this.commandSchemaDefinitions,
      name,
      type,
      version);

    if (schemaDefinition.schema.handler) {
      var sinks = sinksOverride || schemaDefinition.schema.sinks || [];
      const data = schemaDefinition.parse(inputData, sinks);

      // Pass current helper into the handler so that the handler can call another schema, if needed
      const handler = new schemaDefinition.schema.handler(this);
      handler.load(schemaDefinition.type, schemaDefinition.version, sinks, data, internal, schemaDefinition.schema.config);
    }
  }
}

export default SchemaHelper;
