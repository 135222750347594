import { isDelegated, getCustomerId, getDelegateCustomerId, getEmployeeId, getFederationPartnerId, getDelegateEmployeeId } from '@tcc/shared/src/helpers/auth';
import VisitHelperTcc from '../../helpers/visitHelperTcc';
import { getClientId } from '../../integrations/google/tracker';
import { getNavigator, getWindow, getDocument, getHost } from '@tcc/shared/src/helpers/browser';
import config from '@tcc/shared/src/helpers/config';
import { hasAnalyticsConsent, hasMarketingConsent, hasSupportConsent } from '@tcc/shared/src/helpers/policy';
import { getContentGroup, getIsc, getPrivateLabelId } from '@tcc/shared/src/helpers/page';
import { getCleanUrl, getQueryStringParameter } from '@tcc/shared/src/helpers/url';
import page from '@tcc/shared/src/interfaces/traffic/eventPageProperties';
import { findCookie } from '@tcc/shared/src/helpers/cookie';
import { merge } from '@tcc/shared/src/helpers/object';

const visitHelper = new VisitHelperTcc();

/*
  The below mapping controls how we build the global context.

  All values must return either a primitive type or undefined
  to ensure the value between each event can be compared w/o a
  deep/nested evaluation.
*/
const _keyFnMap = {
  'context.visitorId': ({ visitInfo }) => visitInfo.visitorGuid,
  'context.sessionId': ({ visitInfo }) => visitInfo.visitGuid,
  'context.customerId': () => getCustomerId(),
  'context.googleClientId': () => getClientId(),
  'context.isDelegated': ({ delegated }) => delegated,
  'context.employeeId': () => getEmployeeId(true),
  'context.parent.customerId': ({ delegated }) => delegated ? getDelegateCustomerId() : undefined,
  'context.parent.agentId': ({ delegated }) => delegated ? getDelegateEmployeeId(true) : undefined,
  'client.userAgent': () => getNavigator().userAgent,
  // TODO: When we modularize our client, rename this to SCC
  'client.sdk.name': () => 'tcc',
  'client.sdk.version': () => config.get('tcc.buildVersion'),
  'client.device.viewportWidth': ({ win, doc }) => win.innerWidth ? win.innerWidth : doc.body.offsetWidth,
  'client.device.viewportHeight': ({ win, doc }) => win.innerHeight ? win.innerHeight : doc.body.offsetHeight,
  'client.device.screenResolutionWidth': ({ win }) => win.screen.width,
  'client.device.screenResolutionHeight': ({ win }) => win.screen.height,
  'attribution.isc': () => getIsc(),
  'mobile.applicationName': () => getQueryStringParameter('native_app_name', true),
  'mobile.applicationVersion': () => getQueryStringParameter('native_app_version', true),
  'mobile.deviceId': () => getQueryStringParameter('mdeviceid', true),
  'consent.analyticsFlag': () => hasAnalyticsConsent(),
  'consent.marketingFlag': () => hasMarketingConsent(),
  'consent.supportFlag': () => hasSupportConsent(),
  'page.id': () => config.get('tcc.pageId'),
  'page.traceId': () => page.get('trace_id'),
  'page.contentGroup': () => getContentGroup(),
  'page.host': () => getHost(),
  'page.path': ({ win }) => win.location.pathname,
  'page.virtualPath': () => page.get('virtual_path'),
  'page.location': () => getCleanUrl(),
  'page.referrer': ({ doc }) => doc.referrer,
  'page.sessionPageViewCount': ({ visitInfo }) => visitInfo.pageCount,
  'site.federationPartnerId': () => getFederationPartnerId(),
  'site.market': () => findCookie('market'),
  'site.privateLabelId': () => getPrivateLabelId()
};

class GlobalContext {
  init({ traceId }) {
    this.version = 0;
    this.context = { traceId };
    this._contextMap = {};
  }

  /**
   * @description Converts a flat object into a nested structure by recursively
   *  parsing the JSON path from each key.
   * @param { object } contextMap key/value where the key will be the JSON path
   *  in the resulting object { 'path.to.key': 1234 }
   * @returns { object } A structured global context
   *
   * @example
   * {
   *   path: {
   *     to: {
   *       key: 1234
   *     }
   *   }
   * }
   */
  _buildContext(contextMap) {
    const newContext = {
      traceId: this.context.traceId
    };

    Object.entries(contextMap).forEach(([path, value]) => {
      const keys = path.split('.');
      let propKey;
      let newParent = newContext;
      keys.forEach((key, index) => {
        if (index < keys.length - 1) {
          newParent[key] = newParent[key] || {};
          newParent = newParent[key];
        } else {
          propKey = key;
        }
      });

      newParent[propKey] = value;
    });

    return newContext;
  }

  updateContext() {
    const visitInfo = visitHelper.getVisitInfo();
    const win = getWindow();
    const doc = getDocument();
    const delegated = isDelegated();

    let hasChanged = false;

    const contextMap = {};

    Object.entries(_keyFnMap).forEach(([path, fn]) => {
      const newValue = fn({ visitInfo, delegated, win, doc });

      if (this._contextMap[path] !== newValue) {
        hasChanged = true;
      }

      if (typeof newValue !== 'undefined') {
        contextMap[path] = newValue;
      }
    });

    if (hasChanged) {
      this.version++;
      this.context = this._buildContext(contextMap);
      this._contextMap = contextMap;
    }
  }
}

const _globalContext = new GlobalContext();

/**
 * @name updateConsent
 * @param {Object} globalContext the global context which needs to be updated
 * @description Update analytics and marketing consent within an existing
 * global context using current values from the consent cookie
 */
const updateConsent = (globalContext) => {
  const global = globalContext || {};
  global.consent = merge(global.consent, {
    analyticsFlag: hasAnalyticsConsent(),
    marketingFlag: hasMarketingConsent(),
    supportFlag: hasSupportConsent()
  });
};

/**
 * @name updateConsent
 * @param {Object} globalContext the global context which needs to be updated
 * @description Update analytics and marketing consent within an existing
 * global context using current values from the consent cookie
 */
const updateGoogleIds = (globalContext) => {
  const global = globalContext || {};
  global.context = merge(global.context, {
    googleClientId: getClientId()
  });
};

export default _globalContext;

export {
  updateConsent,
  updateGoogleIds
};

// Private exports for unit tests
export {
  GlobalContext
};
