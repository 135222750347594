import { isDebug } from './environment';
import { getWindow } from './browser';

const _logInit = (...args) => {
  const console = getWindow().console;
  if (process.env.NODE_ENV !== 'production' && console && console.log) {
    if (Function.prototype.bind) {
      return Function.prototype.bind.call(console.log, console);
    }
    return () => {
      Function.prototype.apply.call(console.log, console, args);
    };

  }
  return () => {
  };
};

const log = _logInit();

const debug = (...args) => {
  if (isDebug()) {
    log.apply(null, args);
  }
};

const _errorInit = (...args) => {
  const console = getWindow().console;
  if (process.env.NODE_ENV !== 'production' && console && console.error) {
    if (Function.prototype.bind) {
      return Function.prototype.bind.call(console.error, console);
    }
    return () => {
      Function.prototype.apply.call(console.error, console, args);
    };
  }
  return () => {
  };
};

const error = _errorInit();

export {
  log,
  debug,
  log as info,
  error
};

// Private exports for testing
export {
  _logInit,
  _errorInit
};
