import { map } from '../helpers/object';

class SchemaHandler {

  constructor(schemaHelper) {
    this.schemaHelper = schemaHelper;
  }

  // Abstract
  preProcess() {
  }

  load(schemaType, schemaVersion, sinks, data, internal, config) {
    this.schemaType = schemaType;
    this.schemaVersion = schemaVersion;
    this.sinks = ['ALL'].concat(sinks);
    this.data = data;
    this.internal = internal ? internal : {};
    this.config = config ? config : {};
    this.preProcess();
    this.process();
  }

  process(sinkFnMap) {
    if (this.sinks && this.sinks.length > 0) {
      map(sinkFnMap, (sink, fn) => {
        if (this.sinks.indexOf(sink) !== -1) {
          fn(this.data[sink]);
        }
      });
    }
  }
}

export default SchemaHandler;
