import SchemaHandler from '@tcc/shared/src/schema/schemaHandler';
import { map, merge } from '@tcc/shared/src/helpers/object';
import { isArray } from '@tcc/shared/src/helpers/array';
import config from '@tcc/shared/src/helpers/config';

class ConfigHandler extends SchemaHandler {
  process() {
    super.process({
      ALL: (input) => { this._handle(input); }
    });
  }

  _handle(input) {
    map(input.config, (key, value) => {
      if (!input.overwrite) {
        const existing = config.get(key);
        if (isArray(existing)) {
          value = [].concat(existing, value);
        } else if (typeof existing === 'object') {
          value = merge(existing, value);
        }
      }
      config.set(key, value);
    });
  }
}

export default ConfigHandler;
