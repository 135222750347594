import { error } from '../helpers/logger';
import { merge } from '../helpers/object';
import { getDocument, getWindow } from '../helpers/browser';
import { supportsAsync } from '../helpers/http';

const MAX_GLOBAL_BATCH_SIZE = 10;
const INTERVAL_TIME = 250;

// Default which can be overriden
const MAX_EVENTS_PER_REQUEST = 30;

class EventBatcher {
  constructor(sendBatchCallback, maxEvents = MAX_EVENTS_PER_REQUEST) {
    this._eventQueue = [];
    this._sendBatchCallback = sendBatchCallback;
    this._maxEvents = maxEvents;

    // Attaches to unload events / fires a flush
    this._bindUnload();

    // Watch/process any records pushed to the _queue
    this._processInterval = setInterval(() => { this._sendBatch(); }, INTERVAL_TIME);
  }

  _bindUnload() {
    const handler = this.flush.bind(this);

    const attachEvent = getWindow().attachEvent;
    const winEventListener = getWindow().addEventListener;
    const docEventListener = getDocument().addEventListener;

    if (winEventListener) {
      winEventListener('beforeunload', handler, false);
      winEventListener('pagehide', handler, false);
    } else if (attachEvent) {
      attachEvent('beforeunload', handler);
    }

    if (docEventListener) {
      docEventListener('visibilitychange', (() => {
        if (getDocument().visibilityState === 'hidden') {
          this.flush();
        }
      }).bind(this));
    }
  }

  // Takes in a request map and creates multiple HTTP requests to the
  // event bus. Note that this function will split up/ensure that the lowest
  // level array of events will never have more than 10 events.
  _sendRequests(requestMap) {
    Object.entries(requestMap).forEach(([requestKey, request]) => {
      try {
        const [apiKey, schemaId] = requestKey.split('|');
        const payload = {
          schemaId,
          data: []
        };

        Object.values(request).forEach(batch => {
          if (batch.events.length > MAX_GLOBAL_BATCH_SIZE) {
            // Within each batch we can have at most 10 events
            // This will create multiple batches for each set of 10 events
            const batchCount = batch.events.length / MAX_GLOBAL_BATCH_SIZE;
            for (let i = 0 ; i < batchCount; i++) {
              payload.data.push(merge(batch, {
                // i == 0: grab from index 0, 10 elements
                // i == 1: grab from index 10, 10 elements
                // i == 2: grab from index 20, 10 elements
                events: batch.events.slice(i * MAX_GLOBAL_BATCH_SIZE, (i * MAX_GLOBAL_BATCH_SIZE) + MAX_GLOBAL_BATCH_SIZE)
              }));
            }
          } else {
            payload.data.push(batch);
          }
        });

        this._sendBatchCallback(payload, apiKey);
      } catch (err) {
        error(`Failed to send request to event bus: ${err}`);
      }
    });
  }

  // Queue up a new event for the event bus.
  // The incomingEvent object must have the following properties:
  //   - apiKey: the API key to be used in the Event Bus request
  //   - schemaId: the global schema URN which will be used
  //   - global: the global context that was fetched from globalContext.js when processing the event
  //   - contextVersion: the version of the global context that was fetched from globalContext.js
  //   - businessContext: optional business context object
  //   - events: array of events in the format { schemaId: <schema URN>, data: { <event payload> }}
  pushEvent(incomingEvent, sendImmediately) {
    if (sendImmediately || !supportsAsync()) {
      this._sendBatch(incomingEvent);
    } else {
      this._eventQueue.push(incomingEvent);
    }
  }

  // Batching rules
  //  1. Can only batch in same request if api keys match
  //  2. Batch global contexts only if version matches and does not have a business context
  _getRequestBatch(requestMap, businessContexts, { apiKey, schemaId, global, contextVersion, businessContext }) {
    // Request key is a combination of the API key + schemaId.
    // We can only batch together in a single request if the API key and schema Id are the same
    const requestKey = `${apiKey}|${schemaId}`;

    const request = requestMap[requestKey] || {};
    requestMap[requestKey] = request;

    let bContextIndex = 0;
    if (businessContext) {
      // Every business context object will get its own identifier (i.e. batch key)
      // because we don't have a way to tell whether two business contexts are equal
      // without performing a deep comparison, which could be expensive for nested objects
      businessContexts.push(businessContext);
      bContextIndex = businessContexts.length;
    }

    // Batch at the payload.data array based on the global context + business context batch key
    const batchKey = `${contextVersion}|${bContextIndex}`;
    const batch = request[batchKey] || {
      global,
      businessContext,
      events: []
    };

    return {
      batch,
      batchKey,
      request
    };
  }

  _processEvent(requestMap, businessContexts, incomingEvent) {
    // Fetch the new batch for the incoming event. This will also map the batch
    // to a request and keep track of the business context to determine whether
    // each event can be be batched together.
    const { batch, batchKey, request } = this._getRequestBatch(requestMap, businessContexts, incomingEvent);

    // Add the first 10 events to the batch
    batch.events.push(...incomingEvent.events.slice(0, this._maxEvents));

    if (incomingEvent.events.length > this._maxEvents) {
      // If there are more events in the batch, push the event back onto the queue.
      // This helps prevent a single producer from stuffing 30+ events into a single
      // call and clogging our buffers (i.e. impacting other producers). Note that if no
      // other events are on the buffer, the remaining events will be picked up right away
      this.pushEvent(merge(
        incomingEvent,
        { events: incomingEvent.events.slice(this._maxEvents) }));
    }

    // Update batch in the request
    request[batchKey] = batch;

    // Return the total amount of events which were added,
    // which was either all of the events, or at most 30
    return Math.min(incomingEvent.events.length, this._maxEvents);
  }

  _sendBatch(immediateEvent) {
    let totalEvents = 0;

    const requestMap = {};
    const businessContexts = [];

    // If there was an immediate event, process it
    if (immediateEvent) {
      totalEvents += this._processEvent(requestMap, businessContexts, immediateEvent);
    }

    // Processbatches  from the queue
    while (this._eventQueue.length > 0 && totalEvents < this._maxEvents) {
      totalEvents += this._processEvent(requestMap, businessContexts, this._eventQueue.shift());
    }

    // Converts request map into actual HTTP requests
    this._sendRequests(requestMap);
  }

  flush() {
    // send everything we have in the buffer
    while (this._eventQueue.length > 0) {
      this._sendBatch();
    }
  }
}

export default EventBatcher;
