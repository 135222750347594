import { PropertyDefinitionBlock, ObjectDefinitionBlock, CollectionDefinitionBlock, CalculatedDefinitionBlock } from '@tcc/shared/src/schema/schemaDefinitionBlocks';

import * as interfaceUtils from '@tcc/shared/src/interfaces/interfaceUtils';

// Traffic (TCC) Handlers
import AddPagePerfHandler from './traffic/handlers/addPagePerf';
import AddPerfHandler from './traffic/handlers/addPerf';
import AddEventHandler from './traffic/handlers/addEvent';
import AddExperimentAssignment from './traffic/handlers/addExperimentAssignment';
import AddPromotion from './traffic/handlers/addPromotion';
import AddEcommEvent from './traffic/handlers/addEcommEvent';
import AddPageView from './traffic/handlers/addPageView';
import AddImpression from './traffic/handlers/addImpression';
import AddVirtualPagePerf from './traffic/handlers/addVirtualPagePerf';
import GetTrackingValues from './traffic/handlers/getTrackingValues';
import AddGenericConversion from './traffic/handlers/addGenericConversion';
import GetVariantForExperiment from './traffic/handlers/getVariantForExperiment';

// CSP (SCC) Handlers
import AddMicroEvent from './csp/handlers/addMicroEvent';
import AddClick from './csp/handlers/addClick';
import AddElementAction from './csp/handlers/addElementAction';
import AddImpressions from './csp/handlers/addImpressions';

import { GENERIC_CONVERSION_PREFIX } from '../utils/eidConstants';
import ConfigHandler from './scc/handlers/configHandler';

/*
For documentation on how to use the schema framework, refer to
https://github.secureserver.net/Experimentation/exp-utils/blob/master/test/schema/example/exampleSchema.js
*/

/*      Shared Transform Key Maps  */

const _valueTransformMap = {
  ALL: 'value',
  EVENT_SVC: 'ecomm_value',
  TEALIUM: 'order_total_usd' };

const _productPriceTransformMap = {
  ALL: 'price',
  TEALIUM: 'product_price_usd' };

const _currencyTransformMap = {
  ALL: 'currency',
  TEALIUM: 'order_currency' };

const _itcTransformMap = {
  ALL: 'item_tracking_code',
  GA: 'item_tracking_code_product',
  EVENT_SVC: 'itemTrackingCode' };

/*      Shared Object Schemas      */

const _legacySchemaBlocks = () => {
  return [
    new PropertyDefinitionBlock('custom_properties')
      .doNotOutput(['GA', 'TEALIUM'])
  ];
};

const _experimentAssignmentBlocks = () => {
  return [
    new PropertyDefinitionBlock('experiment_id')
      .required(),
    new PropertyDefinitionBlock('variant_id')
      .required(),
    new PropertyDefinitionBlock('content_id'),
    new PropertyDefinitionBlock('experiment_source'),
    new ObjectDefinitionBlock()
      .substitute(_legacySchemaBlocks())
  ];
};

const _eventObjV2 = () => {
  return [
    new PropertyDefinitionBlock('eid')
      .required(),
    new PropertyDefinitionBlock('href'),
    new PropertyDefinitionBlock('tcode'),
    new PropertyDefinitionBlock('tms'),
    new PropertyDefinitionBlock('ci'),
    new PropertyDefinitionBlock('properties')
  ];
};

const _eventObjV1 = () => {
  return [
    new PropertyDefinitionBlock('dom_element'),
    new PropertyDefinitionBlock('dom_event'),
    new PropertyDefinitionBlock('eid'),
    new PropertyDefinitionBlock('properties')
  ];
};

const _promotionBlocks = (eventObj) => {
  return [
    new PropertyDefinitionBlock('id')
      .required()
      .doNotOutput(['EVENT_SVC']),
    new PropertyDefinitionBlock('name')
      .optional()
      .doNotOutput(['EVENT_SVC']),
    new PropertyDefinitionBlock('creative_name')
      .optional()
      .doNotOutput(['EVENT_SVC']),
    new PropertyDefinitionBlock('creative_slot')
      .optional()
      .doNotOutput(['EVENT_SVC']),
    new ObjectDefinitionBlock()
      .substitute(eventObj)
      .doNotOutput(['GA'])
  ];
};

const _cartTypeProperty = () => {
  return new PropertyDefinitionBlock('cart_type')
    .optional()
    .doNotOutput(['TEALIUM']);
};

// prdct supports v1 purchase
const _productBlocks = () => {
  return [
    new PropertyDefinitionBlock('id')
      .required()
      .transformKeys({ TEALIUM: 'product_id' }),
    new PropertyDefinitionBlock('quantity')
      .required()
      .transformKeys({ TEALIUM: 'product_quantity' }),
    new PropertyDefinitionBlock('name')
      .doNotOutput(['TEALIUM']),
    new PropertyDefinitionBlock('brand')
      .doNotOutput(['TEALIUM']),
    new PropertyDefinitionBlock('variant')
      .doNotOutput(['TEALIUM']),
    new PropertyDefinitionBlock('coupon')
      .doNotOutput(['TEALIUM']),
    new PropertyDefinitionBlock('price')
      .transformKeys(_productPriceTransformMap)
  ];
};

// Support Purchase V1
const _cartProductBlocks = (itemExtensions) => {
  return [
    _cartTypeProperty(),
    new PropertyDefinitionBlock('currency')
      .transformKeys(_currencyTransformMap),
    new PropertyDefinitionBlock('coupon')
      .transformKeys({ TEALIUM: 'source_code' }),

    // In addition to the fields defined by the 'product' map, the
    // objects in this collection will also require the price field.
    new CollectionDefinitionBlock().map('items', _productBlocks())
      .extend([
        // This is an example of the 'product' object being extended
        // to require additional properties
        new PropertyDefinitionBlock('full_product_name')
          .doNotOutput(['EVENT_SVC'])
          .transformKeys({ TEALIUM: 'product_name' }),
        new PropertyDefinitionBlock('cj_product_id')
          .doNotOutput(['EVENT_SVC']),
        new PropertyDefinitionBlock('cj_product_price_usd')
          .doNotOutput(['EVENT_SVC']),
        new PropertyDefinitionBlock('cj_product_quantity')
          .doNotOutput(['EVENT_SVC']),
        new PropertyDefinitionBlock('item_tracking_code')
          .transformKeys(_itcTransformMap),
        new PropertyDefinitionBlock('product_category_id')
          .doNotOutput(['EVENT_SVC'])
          .transformKeys({ TEALIUM: 'product_category' }),
        new PropertyDefinitionBlock('category')
          .doNotOutput(['TEALIUM'])
      ].concat(itemExtensions))
      .transform({ EVENT_SVC: [JSON.stringify] })
      .withMinElements(1)
  ];
};

// Supports Purchase V1
const _gaPurchaseBlocks = () => {
  return [
    new PropertyDefinitionBlock('transaction_id')
      .required()
      .transformKeys({ TEALIUM: 'order_id' }),
    new PropertyDefinitionBlock('activation_redirect'),
    new PropertyDefinitionBlock('first_order'),
    new PropertyDefinitionBlock('hashed_email')
      .doNotOutput(['EVENT_SVC', 'GA']),
    new PropertyDefinitionBlock('new_customer'),
    new PropertyDefinitionBlock('order_discount_usd')
      .transformKeys({ TEALIUM: 'order_discount' }),
    new PropertyDefinitionBlock('order_total_new_usd'),
    new PropertyDefinitionBlock('order_total_renewal_usd'),
    new PropertyDefinitionBlock('order_from_website')
      .doNotOutput(['EVENT_SVC']),
    new PropertyDefinitionBlock('page_type')
      .doNotOutput(['EVENT_SVC', 'GA']),
    new PropertyDefinitionBlock('order_region')
      .doNotOutput(['EVENT_SVC']),
    new PropertyDefinitionBlock('payment_pending'),
    new PropertyDefinitionBlock('payment_processor'),
    new ObjectDefinitionBlock()
      .substitute(_cartProductBlocks([
        // _cartProductObj accepts an array of properties to extend the items object map
        new PropertyDefinitionBlock('price')
          .required()
          .transformKeys(_productPriceTransformMap)
      ]))
      .extend([
        new PropertyDefinitionBlock('currency')
          .required()
          .transformKeys(_currencyTransformMap),
        new PropertyDefinitionBlock('value')
          .required()
          .transformKeys(_valueTransformMap)
      ])
      .transform({
        TEALIUM: [interfaceUtils.transformForTealium]
      })
  ];
};

const _purchaseCommon = () => {
  return [
    new CalculatedDefinitionBlock('new_vs_renewal')
      .doNotOutput(['TEALIUM'])
      .transform(interfaceUtils.calcNewVsRenewal),
    new CalculatedDefinitionBlock('eid')
      .doNotOutput(['TEALIUM'])
      .transform(interfaceUtils.calcPurchaseEid)
      .transformKeys({ EVENT_SVC: 'e_id' })
  ];
};

const _checkoutProductBlocks = (staticEid) => {
  return [
    // Extend the cart_prdct object with extra properties
    new ObjectDefinitionBlock().substitute(_cartProductBlocks([
      // _cartProductObj accepts an array of properties to extend the items object map
      new PropertyDefinitionBlock('price')
        .optional()
        .transformKeys(_productPriceTransformMap)
    ])).extend([
      // This is extending the root object, not any of the object maps (such as items or packages)
      new PropertyDefinitionBlock('checkout_step')
        .required(),
      new PropertyDefinitionBlock('checkout_option')
        .optional(),
      new CalculatedDefinitionBlock('eid')
        .transform(staticEid)
        .transformKeys({ EVENT_SVC: 'e_id' })
        .doNotOutput(['TEALIUM']),
      new PropertyDefinitionBlock('eid_label')
        .optional()
        .transformKeys({ EVENT_SVC: 'event_label' })
        .doNotOutput(['TEALIUM'])
    ])
  ];
};

// supports v1 add_to_cart, v1 product_impression and v2 purchase
const _baseProductBlocks = () => {
  return [
    new PropertyDefinitionBlock('id')
      .required()
      .transformKeys({ TEALIUM: 'product_id' }),
    new PropertyDefinitionBlock('qt')
      .required()
      .transformKeys({ ALL: 'quantity', TEALIUM: 'product_quantity' }),
    new PropertyDefinitionBlock('ca')
      .transformKeys({ ALL: 'category', TEALIUM: 'product_category_name' }),
    new PropertyDefinitionBlock('br')
      .doNotOutput(['TEALIUM'])
      .transformKeys({ ALL: 'brand' }),
    new PropertyDefinitionBlock('va')
      .doNotOutput(['TEALIUM'])
      .transformKeys({ ALL: 'variant' }),
    new PropertyDefinitionBlock('cc')
      .doNotOutput(['TEALIUM'])
      .transformKeys({ ALL: 'coupon' }),
    new PropertyDefinitionBlock('pr')
      .transformKeys(_productPriceTransformMap)
  ];
};

// V1 Product Impression and Add To Cart Product Map
const _ecommProductBlocks = () => {
  return [
    new CollectionDefinitionBlock().map('items', _baseProductBlocks())
      .extend([
        new PropertyDefinitionBlock('name')
          .doNotOutput(['TEALIUM']),
        new PropertyDefinitionBlock('itc')
          .transformKeys(_itcTransformMap)
      ])
      .transform({ EVENT_SVC: [JSON.stringify] })
      .withMinElements(1)
  ];
};

// V2 Purchase Product Map
const _purchaseProductBlocks = () => {
  return [
    new CollectionDefinitionBlock().map('items', _baseProductBlocks()).extend([
      new PropertyDefinitionBlock('pr')
        .required()
        .transformKeys(_productPriceTransformMap),
      new PropertyDefinitionBlock('prcaid')
        .optional()
        .sinks(['TEALIUM'])
        .transformKeys({ TEALIUM: 'product_category' }),
      new PropertyDefinitionBlock('cj')
        .optional()
        .sinks(['TEALIUM']),
      new PropertyDefinitionBlock('pritc')
        .optional()
        .transformKeys(_itcTransformMap)
    ]).transform({ EVENT_SVC: [JSON.stringify] })
      .withMinElements(1)
  ];
};

// Supports v1 add_to_cart, v1 product_impression and v2 purchase
const _packageBlocks = () => {
  return [
    new PropertyDefinitionBlock('pkgid')
      .transformKeys({ ALL: 'package_id', EVENT_SVC: 'id' }),
    new PropertyDefinitionBlock('pkgpr')
      .transformKeys({ EVENT_SVC: 'price', TEALIUM: 'package_price_usd' }),
    new PropertyDefinitionBlock('pkgca')
      .transformKeys({ EVENT_SVC: 'category', TEALIUM: 'package_category' }),
    new PropertyDefinitionBlock('pkgqt')
      .transformKeys({ EVENT_SVC: 'quantity', TEALIUM: 'package_quantity' })
  ];
};

// V1 Product Impression, Add To Cart, and Remove From Cart Product map
const _ecommPackageBlocks = () => {
  return [
    new CollectionDefinitionBlock().map('pkgs', _packageBlocks())
      .transform({ EVENT_SVC: [JSON.stringify] })
      .transformKeys({ EVENT_SVC: 'packages' })
  ];
};

// V1 Common Blocks Between Add to Cart and Remove From Cart Schemas
const _cartChangeBlocks = (staticEid) => {
  return [
    new PropertyDefinitionBlock('el')
      .doNotOutput(['TEALIUM'])
      .transformKeys({ GA: 'eid_label', EVENT_SVC: 'event_label' }),
    new CalculatedDefinitionBlock('eid')
      .doNotOutput(['TEALIUM'])
      .transform(staticEid)
      .transformKeys({ EVENT_SVC: 'e_id' }),
    new PropertyDefinitionBlock('currency')
      .transformKeys(_currencyTransformMap),
    new PropertyDefinitionBlock('value')
      .transformKeys(_valueTransformMap),
    new ObjectDefinitionBlock()
      .substitute(_ecommProductBlocks())
      .transform({
        TEALIUM: [interfaceUtils.transformForTealiumV2]
      }),
    new ObjectDefinitionBlock()
      .substitute(_ecommPackageBlocks())
      .transform({
        GA: [interfaceUtils.getPackageIds],
        TEALIUM: [interfaceUtils.transformForTealiumV2]
      }),
    new ObjectDefinitionBlock()
      .substitute(_legacySchemaBlocks()),
    _cartTypeProperty()
  ];
};

// V2 Purchase data map
const _purchaseBlocks = () => {
  return [
    new PropertyDefinitionBlock('cu')
      .required()
      .transformKeys(_currencyTransformMap),
    new PropertyDefinitionBlock('ti')
      .required()
      .transformKeys({ ALL: 'transaction_id', TEALIUM: 'order_id' }),
    new PropertyDefinitionBlock('tr')
      .required()
      .transformKeys(_valueTransformMap),
    new PropertyDefinitionBlock('tcc')
      .transformKeys({ ALL: 'coupon', TEALIUM: 'source_code' }),
    new PropertyDefinitionBlock('fo')
      .transformKeys({ ALL: 'first_order' }),
    new PropertyDefinitionBlock('nc')
      .transformKeys({ ALL: 'new_customer' }),
    new PropertyDefinitionBlock('tdr')
      .transformKeys({ ALL: 'order_discount_usd' }),
    new PropertyDefinitionBlock('tr_new')
      .doNotOutput('GA')
      .transformKeys({ ALL: 'order_total_new_usd' }),
    new PropertyDefinitionBlock('tr_renew')
      .doNotOutput('GA')
      .transformKeys({ ALL: 'order_total_renewal_usd' }),
    new PropertyDefinitionBlock('pp')
      .transformKeys({ ALL: 'payment_pending' }),
    new PropertyDefinitionBlock('psrc')
      .doNotOutput(['TEALIUM'])
      .transformKeys({ ALL: 'payment_processor' }),
    new ObjectDefinitionBlock()
      .substitute(_ecommPackageBlocks())
      .transform({
        TEALIUM: [interfaceUtils.transformForTealiumV2]
      }),
    new ObjectDefinitionBlock()
      .substitute(_purchaseProductBlocks())
      .transform({
        TEALIUM: [interfaceUtils.transformForTealiumV2]
      })
  ];
};

const navTimingBlocks = () => {
  return [
    new PropertyDefinitionBlock('navigationStart')
      .required(),
    new PropertyDefinitionBlock('fetchStart'),
    new PropertyDefinitionBlock('domainLookupStart'),
    new PropertyDefinitionBlock('domainLookupEnd'),
    new PropertyDefinitionBlock('connectStart'),
    new PropertyDefinitionBlock('connectEnd'),
    new PropertyDefinitionBlock('requestStart'),
    new PropertyDefinitionBlock('responseStart'),
    new PropertyDefinitionBlock('responseEnd'),
    new PropertyDefinitionBlock('domLoading'),
    new PropertyDefinitionBlock('domInteractive'),
    new PropertyDefinitionBlock('domContentLoaded'),
    new PropertyDefinitionBlock('domComplete'),
    new PropertyDefinitionBlock('loadEventStart')
      .required(),
    new PropertyDefinitionBlock('loadEventEnd')
  ];
};

const genericConversionBlocks = () => {
  return [
    new ObjectDefinitionBlock().map('properties', [
      new PropertyDefinitionBlock('virtual_order_id')
        .optional().
        transformKeys({ TEALIUM: 'order_id' }),
      new PropertyDefinitionBlock('app_name')
        .optional(),
      new PropertyDefinitionBlock('package_id')
        .optional(),
      new PropertyDefinitionBlock('package_category')
        .optional()
    ]),
    new PropertyDefinitionBlock('area')
      .doNotOutput(['TEALIUM'])
      .required(),
    new PropertyDefinitionBlock('product')
      .doNotOutput(['TEALIUM'])
      .required(),
    new PropertyDefinitionBlock('revenue')
      .doNotOutput(['TEALIUM'])
      .required(),
    new PropertyDefinitionBlock('action')
      .doNotOutput(['TEALIUM'])
      .required(),
    new CalculatedDefinitionBlock('eid')
      .transform((_, siblings) => {
        return `${GENERIC_CONVERSION_PREFIX}${siblings.area}.${siblings.product}.${siblings.revenue}.${siblings.action}`;
      })
  ];
};

const _getVariantForExpBlocks = () => {
  return [
    new PropertyDefinitionBlock('experiment_id')
      .required(),
    new PropertyDefinitionBlock('callback')
      .required(),
    new PropertyDefinitionBlock('attributes'),
    new ObjectDefinitionBlock()
      .substitute(_legacySchemaBlocks())
  ];
};

const _experimentAssignmentSchema = () => {
  return {
    // Becauses this schema handler calls the add_event handler,
    // the sink defined here will override the add_event schema sink
    sinks: ['EVENT_SVC'],
    handler: AddExperimentAssignment,
    data: [
      new ObjectDefinitionBlock()
        .substitute(_experimentAssignmentBlocks())
    ]
  };
};

const _promoClickSchema = (eventObj) => {
  return {
    handler: AddPromotion,
    sinks: ['GA', 'EVENT_SVC'],
    data: [
      new ObjectDefinitionBlock()
        .substitute(_promotionBlocks(eventObj))
        .transform({ GA: [(input) => {
          return { promotions: [input] };
        }] }),
      new ObjectDefinitionBlock()
        .substitute(_legacySchemaBlocks())
    ]
  };
};

const _promoImpressionSchema = (eventObj) => {
  return {
    handler: AddPromotion,
    sinks: ['GA', 'EVENT_SVC'],
    data: [
      new CollectionDefinitionBlock()
        .map('impressions', _promotionBlocks(eventObj))
        .transformKeys({ ALL: 'promotions' })
        .withMinElements(1),
      new ObjectDefinitionBlock()
        .substitute(_legacySchemaBlocks())
    ]
  };
};

const _addEventSchema = (eventObj) => {
  return {
    handler: AddEventHandler,
    sinks: ['GA', 'EVENT_SVC'],
    data: [
      new PropertyDefinitionBlock('type')
        .required()
        .transform({ ALL: [interfaceUtils.filterLogType] }),
      new ObjectDefinitionBlock()
        .substitute(eventObj)
        .transform(interfaceUtils.validateEid),
      new PropertyDefinitionBlock('event_label'),
      new ObjectDefinitionBlock()
        .substitute(_legacySchemaBlocks())
    ]
  };
};

const _addImpressionSchema = (eventObj) => {
  return {
    handler: AddImpression,
    sinks: ['GA', 'EVENT_SVC'],
    data: [
      new ObjectDefinitionBlock()
        .substitute(eventObj)
        .transform(interfaceUtils.validateEid),
      new ObjectDefinitionBlock()
        .substitute(_legacySchemaBlocks())
    ]
  };
};

/*                      CSP Handler Blocks
--------------------------------------------------------------------
Note: Our "CSP" handler blocks will be used to replace
  the old blocks which were added for Traffic & GA. These handlers
  are built using better patterns for ensuring events are always
  sent to the Event Bus.

  Eventually, these will only be used to integrate with the Event Bus
  and Google Tag Manager (for tagging). For now, they bifurcate
  events to every sink by calling legacy handlers directly.

  As part of the CSP handlers, we are going to stop using any notion
  of a sink in this module's configuration. This includes `.sinks`,
  `.doNotOutput`, or `transformKeys`. Note `transform` is allowed,
  but only if using the `ALL` key.

  This is being done to prevent the creation of multiple parse trees
  when ingesting an incoming event. For each sink introduced, a new
  parse tree is created which is computationally expensive.

  For the CSP handlers we will still encourage the reusing of DefinitionBlocks
  as many schemas shared identical properties/objects. */

/**
 * @param { array } extensions Array of properties to be extended. This
 *  is used by the element action interface to add the action property
 * @returns { array } Blocks which can be reused
 * @description This function will return the blocks
 *  used to build the element for all element related
 *  CSP interfaces (clicks, impressions, element action)
 */
const _elementBlocks = (extensions) => [
  new ObjectDefinitionBlock()
    .map('element', [
      new PropertyDefinitionBlock('area')
        .required(),
      new PropertyDefinitionBlock('product')
        .required(),
      new PropertyDefinitionBlock('section')
        .required(),
      new PropertyDefinitionBlock('widget')
        .required()
    ])
    .required()
    .extend(extensions)
];

/**
 * @param { array } extensions Array of properties to be extended. This
 * is used by the element related interfaces (click/impression/element action)
 * to support EIDs alongside custom properties
 * @returns { array } Blocks which can be reused
 * @description This function will return the blocks
 * used to build the traffic object for all CSP interfaces
 */
const _trafficBlocks = (extensions) => [
  new ObjectDefinitionBlock()
    .map('traffic', [
      new PropertyDefinitionBlock('customProperties')
    ])
    .extend(extensions)
];

/**
 * @returns { array } Blocks which can be reused
 * @description This function will return the blocks
 * used to build the traffic object for element related
 * CSP interfaces (clicks, impressions, and element action)
 */
const _elementTrafficBlocks = () => [
  new ObjectDefinitionBlock()
    .substitute(_trafficBlocks([
      new PropertyDefinitionBlock('eid')
    ]))
];

/**
 * @returns { array } Blocks which can be reused
 * @description This function will return the blocks
 * used for the element action interface
 */
const _elementActionBlocks = () => [
  new ObjectDefinitionBlock()
    .substitute(_elementBlocks([
      new PropertyDefinitionBlock('action')
        .required()
        .allowedValues(['blur', 'drag', 'focus', 'hover', 'load', 'scroll'])
    ])),
  new ObjectDefinitionBlock()
    .substitute(_elementTrafficBlocks())
];

/**
 * @returns { array }Blocks which can be reused
 * @description This function will return the common blocks
 * for the newer CSP promotion interfaces (clicks and impressions)
 */
const _promoBlocks = () => [
  new ObjectDefinitionBlock()
    .substitute(_elementBlocks()),
  new ObjectDefinitionBlock()
    .substitute(_elementTrafficBlocks()),
  new ObjectDefinitionBlock().map('promotion', [
    new PropertyDefinitionBlock('id')
      .required(),
    new PropertyDefinitionBlock('name'),
    new PropertyDefinitionBlock('creative'),
    new PropertyDefinitionBlock('position')
  ]),
  new ObjectDefinitionBlock().map('product', [
    new PropertyDefinitionBlock('actionCode')
      .required()
      .allowedValues(['add_to_cart', 'product_detail']),
    new ObjectDefinitionBlock().map('basket', [
      new PropertyDefinitionBlock('couponCode'),
      new PropertyDefinitionBlock('currencyCode'),
      new PropertyDefinitionBlock('itemTrackingCode')
    ]),
    new CollectionDefinitionBlock().map('products', [
      new PropertyDefinitionBlock('productId')
        .required(),
      new PropertyDefinitionBlock('productName'),
      new PropertyDefinitionBlock('productInstanceId'),
      new PropertyDefinitionBlock('priceUsd'),
      new PropertyDefinitionBlock('quantity')
        .required(),
      new PropertyDefinitionBlock('couponCode'),
      new PropertyDefinitionBlock('itemTrackingCode')
    ])
      .required(),
    new CollectionDefinitionBlock().map('packages', [
      new PropertyDefinitionBlock('id'),
      new PropertyDefinitionBlock('priceUsd'),
      new PropertyDefinitionBlock('quantity'),
      new PropertyDefinitionBlock('category')
    ])
  ])
];

/*      Command Schemas     */

export default {
  /*     CSP Schemas
  The below schemas are following our newest pattern
  and integrate with the Customer Signals Platform /
  Event Bus directly. Eventually, our legacy and traffic
  schemas will either be upgraded to the new
  pattern or will simply be removed. */

  add_click: {
    v1: {
      handler: AddClick,
      data: [
        new ObjectDefinitionBlock()
          .substitute(_promoBlocks())
          .transform(interfaceUtils.validateElement)
      ]
    }
  },
  add_element_action: {
    v1: {
      handler: AddElementAction,
      data: [
        new ObjectDefinitionBlock()
          .substitute(_elementActionBlocks())
          .transform(interfaceUtils.validateElement)
      ]
    }
  },
  add_impressions: {
    v1: {
      handler: AddImpressions,
      data: [
        new CollectionDefinitionBlock()
          .map('impressions', [
            /* Common blocks can be provided
                for each impression in the array */
            new ObjectDefinitionBlock()
              .substitute(_promoBlocks())
              .transform(interfaceUtils.validateElement)
          ])
          .withMinElements(1)
      ]
    }
  },
  add_virtual_page_view: {
    v1: {
      handler: AddPageView,
      sinks: ['GA', 'EVENT_SVC'],
      data: [
        new PropertyDefinitionBlock('virtualPath')
          .required(),
        new ObjectDefinitionBlock()
          .substitute(_trafficBlocks())
      ]
    }
  },
  add_micro_events: {
    v1: {
      handler: AddMicroEvent,
      data: [
        new PropertyDefinitionBlock('apiKey')
          .required(),
        new PropertyDefinitionBlock('schemaId')
          .required(),
        new CollectionDefinitionBlock()
          .map('events', [
            new PropertyDefinitionBlock('schemaId')
              .required(),
            new PropertyDefinitionBlock('data')
              .required()
          ])
          .withMinElements(1),
        new ObjectDefinitionBlock()
          .map('businessContext', [
            new PropertyDefinitionBlock('schemaId')
              .required(),
            new PropertyDefinitionBlock('data')
              .required()
          ])
      ]
    }
  },

  /*     SCC Schemas
  The below schemas are used to interact with SCC, such as
  configuring the client or fetching current tracking values. */
  set_config: {
    v1: {
      handler: ConfigHandler,
      data: [
        new ObjectDefinitionBlock()
          .map('config', [
            new PropertyDefinitionBlock('components')
          ])
          .required(),
        new PropertyDefinitionBlock('overwrite')
      ]
    }
  },
  get_tracking_values: {
    v1: {
      handler: GetTrackingValues,
      data: [
        new PropertyDefinitionBlock('callback').required()
      ]
    }
  },
  /*     Legacy Traffic Schemas
  The below schemas are in place until we have moved
  everyone to the new CSP schemas which integrated with
  the Event Bus primarily and bifurcate to old sinks. */

  add_event: {
    v1: _addEventSchema(_eventObjV1()),
    v2: _addEventSchema(_eventObjV2())
  },
  add_impression: {
    // The v1/v2 are legacy schemas and will eventually be removed
    // in favor of the newer CSP "add_impressions" interface
    v1: _addImpressionSchema(_eventObjV1()),
    v2: _addImpressionSchema(_eventObjV2())
  },
  add_generic_conversion: {
    v1: {
      handler: AddGenericConversion,
      sinks: ['GA', 'EVENT_SVC', 'TEALIUM'],
      data: [
        new ObjectDefinitionBlock()
          .substitute(genericConversionBlocks())
          .transform({
            TEALIUM: [interfaceUtils.unnestProperties]
          }),
        new ObjectDefinitionBlock()
          .substitute(_legacySchemaBlocks())
      ]
    }
  },
  add_page_request: {
    v1: {
      handler: AddPageView,
      sinks: ['GA', 'EVENT_SVC'],
      data: [
        new PropertyDefinitionBlock('virtual_path'),
        new ObjectDefinitionBlock()
          .substitute(_legacySchemaBlocks())
      ]
    }
  },
  add_virtual_page_perf: {
    v1: {
      handler: AddVirtualPagePerf,
      sinks: ['EVENT_SVC'],
      data: [
        new PropertyDefinitionBlock('virtual_path')
          .required(),
        new ObjectDefinitionBlock()
          .map('timing_metrics', navTimingBlocks())
          .required(),
        new PropertyDefinitionBlock('perf_mark_name'),
        new ObjectDefinitionBlock()
          .substitute(_legacySchemaBlocks())
      ]
    },
    v2: {
      handler: AddVirtualPagePerf,
      sinks: ['EVENT_SVC'],
      data: [
        new PropertyDefinitionBlock('type')
          .required(),
        new ObjectDefinitionBlock()
          .map('timing', [
            new ObjectDefinitionBlock()
              .map('navigation', navTimingBlocks())
              .required()
          ])
          .required()
      ]
    }
  },
  add_page_perf: {
    v1: {
      handler: AddPagePerfHandler,
      data: [
        new ObjectDefinitionBlock()
          .substitute(_legacySchemaBlocks())
      ],
      // add_page_perf still depends on add_perf in a legacy way.
      // If you need to control where the output for this schema will go,
      // adjust the 'add_perf' output group
      sinks: ['EVENT_SVC']
    }
  },
  // Ad-hoc perf interface.
  // add_page_perf reuses this schema to log perf data
  // Helper libraries / consumers use this to log virtual page requests
  add_perf: {
    v1: {
      handler: AddPerfHandler,
      sinks: ['EVENT_SVC'],
      data: [
        new PropertyDefinitionBlock('type')
          .required()
          .transform({ ALL: [interfaceUtils.filterLogType] }),
        new PropertyDefinitionBlock('properties'),
        new ObjectDefinitionBlock()
          .substitute(_legacySchemaBlocks())
      ]
    }
  },
  get_variant_for_experiment: {
    v1: {
      handler: GetVariantForExperiment,
      data: _getVariantForExpBlocks()
    },
    v2: {
      handler: GetVariantForExperiment,
      data: [
        new ObjectDefinitionBlock()
          .substitute(_getVariantForExpBlocks()).extend([
            new PropertyDefinitionBlock('traffic_type')
              .required(),
            new PropertyDefinitionBlock('configuration')
          ])
      ]
    }
  },
  // DEPRECATED. Use add_experiment_assignment
  add_experiment: {
    v1: {
      handler: AddExperimentAssignment,
      sinks: ['EVENT_SVC'],
      data: [
        new ObjectDefinitionBlock()
          .substitute(_experimentAssignmentBlocks()).extend([
            new PropertyDefinitionBlock('experiment_type')
          ])
      ]
    }
  },
  add_experiment_assignment: [
    {
      type: 'abn',
      v1: _experimentAssignmentSchema()
    },
    {
      type: 'mvt',
      v1: _experimentAssignmentSchema()
    }
  ],
  add_promotion: [
    {
      type: 'click',
      v1: _promoClickSchema(_eventObjV1()),
      v2: _promoClickSchema(_eventObjV2())
    },
    {
      type: 'impression',
      v1: _promoImpressionSchema(_eventObjV1()),
      v2: _promoImpressionSchema(_eventObjV2())
    }
  ],
  add_ecomm_event: [
    {
      type: 'product_impression',
      v1: {
        handler: AddEcommEvent,
        sinks: ['TEALIUM', 'EVENT_SVC'],
        data: [
          new PropertyDefinitionBlock('el')
            .doNotOutput(['TEALIUM'])
            .transformKeys({ GA: 'eid_label', EVENT_SVC: 'event_label' }),
          new CalculatedDefinitionBlock('eid')
            .doNotOutput(['TEALIUM'])
            .transform('gpd.exp.tcc.product-impression.success')
            .transformKeys({ EVENT_SVC: 'e_id' }),
          new ObjectDefinitionBlock().substitute(_ecommProductBlocks())
            .transform({
              TEALIUM: [interfaceUtils.transformForTealiumV2]
            }),
          new ObjectDefinitionBlock().substitute(_ecommPackageBlocks())
            .transform({
              GA: [interfaceUtils.getPackageIds],
              TEALIUM: [interfaceUtils.transformForTealiumV2]
            }),
          new ObjectDefinitionBlock()
            .substitute(_legacySchemaBlocks()),
          _cartTypeProperty()
        ]
      }
    },
    {
      type: 'add_to_cart',
      v1: {
        handler: AddEcommEvent,
        sinks: ['GA', 'TEALIUM', 'EVENT_SVC'],
        data: [
          new ObjectDefinitionBlock().substitute(
            _cartChangeBlocks('gpd.exp.tcc.add-to-cart.success'))
        ]
      }
    },
    {
      type: 'remove_from_cart',
      v1: {
        handler: AddEcommEvent,
        sinks: ['GA', 'EVENT_SVC'],
        data: [
          new ObjectDefinitionBlock().substitute(
            _cartChangeBlocks('gpd.exp.tcc.remove-from-cart.success'))
        ]
      }
    },
    {
      type: 'begin_checkout',
      v1: {
        handler: AddEcommEvent,
        sinks: ['GA', 'EVENT_SVC'],
        data: [
          new ObjectDefinitionBlock().substitute(_checkoutProductBlocks(
            'gpd.exp.tcc.begin-checkout.success')),
          new ObjectDefinitionBlock()
            .substitute(_legacySchemaBlocks())
        ]
      }
    },
    {
      type: 'checkout_progress',
      v1: {
        handler: AddEcommEvent,
        sinks: ['GA', 'EVENT_SVC'],
        data: [
          new ObjectDefinitionBlock().substitute(_checkoutProductBlocks(
            'gpd.exp.tcc.checkout-progress.success')).extend([
            new PropertyDefinitionBlock('payment_processor'),
            new ObjectDefinitionBlock()
              .substitute(_legacySchemaBlocks())
          ])
        ]
      }
    },
    {
      type: 'purchase',
      v1: {
        handler: AddEcommEvent,
        sinks: ['GA', 'TEALIUM', 'EVENT_SVC'],
        data: [
          new ObjectDefinitionBlock()
            .substitute(_gaPurchaseBlocks())
            .extend(_purchaseCommon())
            .transform({ TEALIUM: [interfaceUtils.emptyStringForUndefined] }),
          new ObjectDefinitionBlock()
            .substitute(_legacySchemaBlocks())
        ]
      },
      v2: {
        handler: AddEcommEvent,
        sinks: ['GA', 'TEALIUM', 'EVENT_SVC'],
        data: [
          new ObjectDefinitionBlock()
            .substitute(_purchaseBlocks())
            .extend(_purchaseCommon())
            .transform({
              GA: [interfaceUtils.getPackageIds],
              TEALIUM: [interfaceUtils.emptyStringForUndefined]
            }),
          new ObjectDefinitionBlock()
            .substitute(_legacySchemaBlocks())
        ]
      }
    }
  ]
};
