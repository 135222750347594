import { getWindow } from './browser';
import { setWindowVar } from './window';
import { logToArray, isArray } from './array';
import { debug } from './logger';

class DataLayer {
  constructor(dataLayerName) {
    this.dataLayerName = dataLayerName;
    this.dataLayerLog = [];
    this.asyncCommands = [];
    this.loaded = false;
    this.dataLayers = [];

    if (!isArray(this.dataLayerName)) {
      this.dataLayerName = [this.dataLayerName];
    }
    // This will contain any records that were logged to
    // to the global dataLayer BEFORE traffic was loaded.
    this.dataLayerName.forEach((name) => {
      const asyncDataLayer = getWindow()[name];
      if (asyncDataLayer) {
        this.asyncCommands.push(...asyncDataLayer);
      }
      // Update the global dataLayer to use this class
      setWindowVar(name, this);
      this.dataLayers.push(asyncDataLayer);
    });
  }

  start() {
    // Process all records that were added before TCC initialized
    this.loadAsyncCommands();
  }

  pushLog(cmd) {
    if (process.env.NODE_ENV !== 'production') {
      debug('>>> Push to', this.dataLayerName, 'with', cmd);
    }
    logToArray(this.dataLayerLog, cmd);
  }

  // Abstract
  process() {}

  push(cmd) {
    // If the datalayer is not loaded (i.e. the document is not yet loaded), push to the asyncCommands
    if (!this.loaded) {
      this.asyncCommands.push(cmd);
    } else {
      this.process(cmd);
    }
  }

  loadAsyncCommands() {
    while (this.asyncCommands.length > 0) {
      this.process(this.asyncCommands.shift());
    }
    this.loaded = true;
  }
}

export default DataLayer;
