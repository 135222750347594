import { IMPRESSION_SCHEMA_ID } from '../../../utils/schemaConstants';
import AddElementAction from './addElementAction';
import { createGuid } from '@tcc/shared/src/helpers/guid';

class AddImpressions extends AddElementAction {
  _getSchemaId() {
    return IMPRESSION_SCHEMA_ID;
  }

  _getActionType() {
    return 'impression';
  }

  _getEvents(input) {
    // Impressions come in as an array, which we
    // deconstruct for the event bus schema
    const events = [];
    input.impressions.forEach(impression => {
      events.push(...super._getEvents(impression, createGuid()));
    });
    return events;
  }

  _handleLegacy(input) {
    const type = this._getActionType();
    const genericImpressions = [];
    const promoImpressions = [];

    input.impressions.forEach(impression => {
      // As long as one impression has a promotion set, then this is a promo event
      const destination = this._isPromo(impression) ? promoImpressions : genericImpressions;
      destination.push(this._transformLegacyPromo(type, impression.element, impression.traffic, impression.promotion));
    });

    if (promoImpressions.length > 0) {
      // Send to the legacy add_promotion handler with corresponding type
      this.schemaHelper.handleSchema(
        'add_promotion',
        type,
        'v2',
        {
          impressions: promoImpressions
        },
        this.internal);
    }

    // For each event which did NOT have a promotion object,
    // call the legacy add impression handler
    genericImpressions.forEach(impression => {
      this.schemaHelper.handleSchema(
        'add_impression',
        undefined,
        'v2',
        impression,
        this.internal);
    });
  }
}

export default AddImpressions;
