import AbstractEventHandler from './abstractEventHandler';

class AddMicroEvent extends AbstractEventHandler {
  _getBusinessContext() {
    return this.data.ALL.businessContext;
  }

  _getEvents() {
    return this.data.ALL.events;
  }

  // Override the base method so that producers can
  // specify their own API key for this interface
  _getApiKey() {
    return this.data.ALL.apiKey;
  }

  _getGlobalSchemaId() {
    return this.data.ALL.schemaId;
  }
}

export default AddMicroEvent;
