import { getWindow } from '@tcc/shared/src/helpers/browser';

const cmdLogPagePerf = () => {
  getWindow()._expDataLayer.push({
    schema: 'add_page_perf',
    version: 'v1'
  });
};

export default cmdLogPagePerf;
