import SchemaHandler from '@tcc/shared/src/schema/schemaHandler';
import { merge } from '@tcc/shared/src/helpers/object';
import { getISODate } from '@tcc/shared/src/helpers/date';
import { pushEvent as pushEventBus } from '@tcc/shared/src/integrations/eventBus/eventBus';
import globalContext from '../globalContext';
import config from '@tcc/shared/src/helpers/config';
import { error } from '@tcc/shared/src/helpers/logger';
import { pushEvent as pushGtm } from '../../../integrations/google/gtm';
import { getPrivateLabelId } from '@tcc/shared/src/helpers/page';

class AbstractEventHandler extends SchemaHandler {

  process(sinkFnMap) {
    super.process(merge({
      ALL: (input) => this._handle(input)
    }, sinkFnMap));
  }

  _handle(input) {
    try {
      this._handleSinks(input);
    } catch (ex) {
      error('Failed sending event to Event Bus', ex);
    }
    this._handleLegacy(input);
  }

  _handleSinks(input) {
    // Newer v2 handlers are sending to Event Bus/GTM before calling legacy handlers for event service/GA.
    // When the legacy handlers execute, this flag will prevent a duplicate event from sending to Event Bus / GTM.
    if (this.internal.hasFired)
      return;

    const events = this._getEvents(input);
    if (!events || events.length === 0)
      return;

    const eventCreationTimestamp = getISODate();
    for (let i = 0; i < events.length; i++) {
      events[i].data = merge({ eventCreationTimestamp }, events[i].data);
    }

    const globalSchemaId = this._getGlobalSchemaId();
    const businessContext = this._getBusinessContext();

    globalContext.updateContext();

    const baseData = {
      schemaId: globalSchemaId,
      global: globalContext.context,
      contextVersion: globalContext.version,
      businessContext,
      events
    };

    const hasConversion = this._hasConversion();

    // Push to Event Bus
    if (config.get('tcc.eventBusEnabled')) {
      pushEventBus(merge(baseData, {
        apiKey: this._getApiKey()
      }), hasConversion);
    }

    // Push to GTM
    pushGtm(merge(baseData, {
      hasConversion: hasConversion,
      webContext: merge(this._getWebContext(input), {
        // TODO: Update sGTM to pull from producerEventId
        hitId: this.internal.eventId
      })
    }));

    this.internal.hasFired = true;
  }

  // Abstract method (Optional, Required if not overriding _getEvents)
  _getSchemaId() {}

  // Override method (Optional)
  _getEvents(input) {
    return [{
      schemaId: this._getSchemaId(),
      data: input
    }];
  }

  // Override method (Optional)
  _getGlobalSchemaId() {
    if (getPrivateLabelId())
      return 'urn:shared:user:events:/v2';

    // Use V1 when PLID is not available
    return 'urn:shared:user:events:/v1';
  }

  // Override method (Optional)
  _getBusinessContext() {}

  // Override method (Optional)
  _getApiKey() {
    return config.get('tcc.eventBusApiKey');
  }

  // Override method (Optional)
  _hasConversion() {}

  // Override method (Optional)
  _handleLegacy(input) {} // eslint-disable-line no-unused-vars

  // Override method (Optional)
  _getWebContext(input) {} // eslint-disable-line no-unused-vars
}

export default AbstractEventHandler;
