import { libraryLoaded, setClientName } from '@tcc/shared/src/helpers/window';
import { getWindow, getDocument, isSupported } from '@tcc/shared/src/helpers/browser';
import { log, error } from '@tcc/shared/src/helpers/logger';

import SchemaHelper from '@tcc/shared/src/schema/schemaHelper';

import { getPerformanceData, observePerfMetrics } from '@tcc/shared/src/helpers/performance';
import config  from '../src/config/tccConfig';
import trackingValues from '@tcc/shared/src/helpers/trackingValues';
import loadHelper, { withCookieCache } from '@tcc/shared/src/helpers/load';

import { writePolicyCookie } from '@tcc/shared/src/helpers/policy';
import { handleOrderConfirmation } from './helpers/ecomm';
import { init as initConfigManager } from '@tcc/shared/src/helpers/configManager';
import SignalsDataLayer from '@tcc/shared/src/interfaces/signalsDataLayer';
import { init as initEventService } from '@tcc/shared/src/integrations/eventService/eventService';
import { init as initTrafficDataLayer } from '@tcc/shared/src/interfaces/legacy/trafficDataLayer';
import { init as initEventListener } from '@tcc/shared/src/helpers/eventListener';
import { init as initEventBus } from '@tcc/shared/src/integrations/eventBus/eventBus';
import { init as initTTI } from './integrations/tti/tti';
import { init as initEnv } from '@tcc/shared/src/helpers/environment';
import { loadGooglePlatforms } from './integrations/google/helper';

import page from '@tcc/shared/src/interfaces/traffic/eventPageProperties';
import { initializeReferrer } from './interfaces/traffic/eventProperties';
import interfaceSchema from './interfaces/interfaceSchema';
import VisitHelperTcc from './helpers/visitHelperTcc';
import * as handlers from './interfaces/legacy/trafficMethods';
import { initTracing } from '@tcc/shared/src/helpers/page';
import { updateAuthTokens } from '@tcc/shared/src/helpers/auth';
import globalContext from './interfaces/csp/globalContext';

let initialized = false;

const _libraryName = 'tcc';

const _tccInit = () => {
  if (!initialized) {
    initialized = true;

    // Initialize the whole shootin match
    // Load order below is *IMPORTANT*

    // Kickoff perf metrics first so that we can capture entries which will fire early
    observePerfMetrics();

    // Delay push to GA, Traffic, and Tealium sinks until load event
    loadHelper.init(
      getDocument().readyState === 'complete',
      (triggerOnLoad) => {
        getWindow().addEventListener('load', triggerOnLoad);
      }
    );

    // Set the referrer property before GA is initialized
    // Prevents a race condition where GA config and be created before
    // the first pageview event is process and sets the referrer in the property page.
    initializeReferrer(page);

    // Initialize event service forensic logs
    initEventService();

    // Initialize page values such as Visitor and Tracing IDs
    new VisitHelperTcc().getVisitInfo();
    const traceProperties = initTracing();
    page.merge(traceProperties);

    // Add to global context, which is used for Event Bus integration.
    globalContext.init({ traceId: traceProperties.trace_id });

    // Expose tracking values on the window
    getWindow()._tccTrackingValues = trackingValues.properties;

    // Attach to elements with traffic attributes (e.g. data-eid) and listen for clicks
    initEventListener();

    // Initializes logs for event bus sink. Must occur before datalayer is processed.
    initEventBus();

    // Initializes newer _expDataLayer/_signalsDataLayer interfaces
    const signalsDataLayer = new SignalsDataLayer({
      schemaHelper: new SchemaHelper(interfaceSchema),
      pageViewSchema: 'add_page_request',
      autoPageViewDisabled: config.get(`${_libraryName}.manualPageRequest`),
      // Processed on every push to our datalayer
      onProcess: () => {
        // Parses auth cookies and loads values into memory for downstream actions
        updateAuthTokens();
        globalContext.updateContext();
      },
      immediateSchemas: ['get_tracking_values', 'add_page_request', 'add_ecomm_event', 'set_config']
    });

    // The below will only initialize after the page's onload event is complete
    loadHelper.registerOnLoadFn(() => {
      // GA will not be loaded for pass (productivity) pages
      if (config.get(`${_libraryName}.realm`) !== 'pass') {
        loadGooglePlatforms();
      }

      // Load TTI library if not disabled by page
      if (config.get(`${_libraryName}.loadTTI`)) {
        initTTI();
      }

      // Process any events which were pushed before client loaded.
      // Additionally, start processing all incoming events.
      signalsDataLayer.start();

      // Initialize legacy _trfq interfaces
      initTrafficDataLayer(handlers);

      // Check for ecomm cookie & push ecommerce event
      handleOrderConfirmation();

      if (!config.get(`${_libraryName}.manualPagePerf`)) {
        // Only send performance data after the document has loaded
        getPerformanceData(_libraryName, 'auto');
      }
    });

    log('TCC STARTED', config.getProperties());
  }
};

withCookieCache(() => {
  // Only load if the browser is supported
  if (isSupported()) {
    // Initialize window helper with library name
    setClientName(_libraryName);

    // Initialize configs so that we can determine if the library was disabled
    initConfigManager(
      '_gaDataLayer',
      ['tcc', 'gtm'],
      // Known invalid properties, including those which are now internally managed
      ['shopperid', 'marketid', 'currency']);

    // Initialize environment so that we know where to route events
    initEnv(config.get(`${_libraryName}.buildEnv`));

    // Load library only once on page... Throw an error if attempted to load more then once
    if (libraryLoaded()) {
      error('TCC Library has already been loaded on page');
    } else {
      // Set the policy cookie
      writePolicyCookie();

      // Do not init if turned off
      if (config.get('tcc.status') !== 'off') {
        // We should init only when visible
        const doc = getDocument();
        if (doc.visibilityState !== 'prerender') {
          _tccInit();
        } else {
          doc.addEventListener('visibilitychange', () => {
            if (doc.visibilityState !== 'prerender' && doc.visibilityState !== 'unloaded') {
              _tccInit();
            }
          });
        }
      }
    }
  }
});
