import { getWindow } from '@tcc/shared/src/helpers/browser';

const cmdLog = (eventDesc, propertyObject) => {
  getWindow()._expDataLayer.push({
    schema: 'add_perf',
    version: 'v1',
    data: {
      type: eventDesc,
      properties: propertyObject } });
};

export default cmdLog;
