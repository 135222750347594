import { error } from './logger';

const _isFn = (fn) => {
  return fn && {}.toString.call(fn) === '[object Function]';
};

class LoadHelper {
  _executeFn(fn) {
    try {
      fn();
    } catch (e) {
      error('Exception while executing onLoad callback', fn, e);
    }
  }

  _triggerOnLoad() {
    this._loaded = true;
    for (let i = 0; i < this._onLoadFns.length; i++) {
      this._executeFn(this._onLoadFns[i]);
    }
  }

  registerOnLoadFn(fn) {
    if (_isFn(fn)) {
      // If the onLoad has already triggered, execute the fn immediately
      if (this._loaded) {
        this._executeFn(fn);
      } else {
        this._onLoadFns.push(fn);
      }
    } else if (process.env.NODE_ENV !== 'production') {
      error('Callback passed to registerOnLoadFn is not a valid function', fn);
    }
  }

  init(isLoaded, notLoadedCb) {
    this._loaded = false;
    this._onLoadFns = [];

    // If already loaded, set _loaded=true so that functions
    // are processed immediately after they're registered
    if (isLoaded) {
      this._loaded = true;
    } else {
      notLoadedCb(this._triggerOnLoad.bind(this));
    }
  }
}

export default LoadHelper;
