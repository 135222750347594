import { getClickEventData as getBrowserClickEventData } from '../helpers/browser';
import { map } from '../helpers/object';
import { hasInvalidCharacters } from '../../../tcc/src/utils/eidUtils';
import { buildMessage } from '../../../tcc/src/utils/logUtils';

/*      Shared Transformation Functions      */

const emptyStringForUndefined = (output) => {
  map(output, (filter) => {
    if (typeof output[filter] === 'undefined') {
      output[filter] = '';
    }
  });
  return output;
};

const filterLogType = (input) => {
  return input.length > 10 ? input.substr(0, 10) : input || '';
};

const getPackageIds = (data) => {
  if (!data.pkgs) return data;

  const pkgList = [];
  for (let i = 0; i < data.pkgs.length; i++) {
    if (data.pkgs[i].package_id) {
      pkgList.push(data.pkgs[i].package_id);
    }
  }

  // Cleanup GA doesn't need the raw pkg obj
  delete data.pkgs;

  // Set Datakey for filterMap
  data.package_ids = pkgList.join(',');

  return data;
};

// Support V2 CJ purchase transformations
const _transformCjValues = (data) => {
  if (!data.items) return;

  [
    'product_id',
    'product_price_usd',
    'product_quantity'
  ].forEach((name) => {

    // Init Arrays
    data['cj_' + name] = [];

    // Populate Arrays for any items with a true cj flag
    data.items.forEach((item) => {
      if (item.cj === 'true') {
        data['cj_' + name].push(item[name]);
      }
    });

  });

  return data;
};

// Supports all V1 purchase transformations
// TODO Brandon 11/16/2018 Remove after Cart moves to V2 Purchase
// https://jira.godaddy.com/browse/EXP-1107
const transformForTealium = (data) => {
  const values = data.items || data.pkgs;

  for (let i = 0; i < values.length; i++) {
    const keys = Object.keys(values[i]);
    for (let j = 0; j < keys.length; j++) {
      const key = keys[j];
      data[key] = data[key] || [];
      const value = values[i][key];
      if (typeof value !== 'undefined') {
        data[key].push(value);
      }
    }
  }

  // Cleanup, Tealium doesn't need the raw data
  (data.items) ? delete data.items : delete data.pkgs;

  return data;
};

// Supports V2 purchase transformations
const transformForTealiumV2 = (data) => {
  const values = data.items || data.pkgs;

  // Handles all array creations except CJ
  values.forEach((ecommItem) => {
    Object.keys(ecommItem).forEach((key) => {
      data[key] = data[key] || [];
      data[key].push(ecommItem[key] || '');
    });
  });

  // Handle CJ Array Creations
  _transformCjValues(data);

  // Cleanup, Tealium doesn't need the raw data
  ['items', 'pkgs', 'properties'].forEach((key) => {
    if (data[key] === values) delete data[key];
  });
  delete data.cj;

  return data;
};

// Used with V1 Generic Conversions. Used to unnest the props passed in the
// properties object before sending to Tealium.
const unnestProperties = (data) => {
  if (!data.properties) return data;

  Object.keys(data.properties).forEach((key) => {
    data[key] = data.properties[key] || '';
  });

  delete data.properties;

  return data;
};

// Returns calculated new_vs_renewal if dependent sibling properties are available
const calcNewVsRenewal = (_, siblings) => {
  if (isNaN(parseFloat(siblings.order_total_new_usd)) || isNaN(parseFloat(siblings.order_total_renewal_usd)))
    return;

  // loop through productEvals and set newVsRenewal string
  let newVsRenewal;
  map({
    'new-and-renewal-products': (parseFloat(siblings.order_total_new_usd) > 0 && parseFloat(siblings.order_total_renewal_usd) > 0),
    'new-product-only': (parseFloat(siblings.order_total_new_usd) > 0 && parseFloat(siblings.order_total_renewal_usd) === 0),
    'renewal-product-only': (parseFloat(siblings.order_total_new_usd) === 0 && parseFloat(siblings.order_total_renewal_usd) > 0),
    'unknown-products': (parseFloat(siblings.order_total_new_usd) === 0 && parseFloat(siblings.order_total_renewal_usd) === 0)
  }, (key, value) => {
    if (value === true)
      newVsRenewal = key;
  });

  return newVsRenewal;
};

// Returns calculated eid if dependent sibling properties are available
const calcPurchaseEid = (_, siblings) => {
  if (!siblings.new_customer || !siblings.payment_pending || !siblings.new_vs_renewal)
    return;

  const cartType = siblings.cart_type ? siblings.cart_type : 'modular-cart';
  const newCustomer = siblings.new_customer === 'true' ? 'new-customer_' : 'existing-customer_';
  const paymentPending = siblings.payment_pending === 'true' ? 'payment-pending_' : 'payment-processed_';
  return `ecomm.payments.transaction.${newCustomer}${paymentPending}${siblings.new_vs_renewal}.${cartType}.success`;
};

/**
 * @param { object } input an object which should contain an eid property within it
 * @returns { object } the input which was passed + the parsed EID value (in lowercase)
 * @description This function will raise an exception if the provided
 * EID is not found or invalid. The EID will also be transformed to
 * lowercase as part of this.
 */
const validateEid = (input) => {
  // If eid was not provided from input, try to pull from DOM element
  const eid = input.eid || getBrowserClickEventData(input.dom_element, input.dom_event).e_id;

  // If eid was not provided, drop event/throw an error
  if (!eid) {
    throw buildMessage('missing_legacy_eid_error', eid);
  }

  // Convert eid to lowercase before sending to handler
  input.eid = eid.toLowerCase();

  // If there are invalid characters, drop event/throw an error
  if (hasInvalidCharacters(input.eid)) {
    throw buildMessage('invalid_chars_eid_error', input.eid);
  }

  return input;
};

/**
 * @param { object } input traffic object which would have been provided
 * by one of the newer CSP interfaces
 * @returns { object } the input which was passed in
 * @description This function will raise an exception if an EID
 * is provided and is then invalid. This is intended to be used
 * for element related interface schemas and is different from
 * validateEid in that the EID is only validated if set.
 */
const validateElement = (input) => {
  const { traffic } = input;

  // If an eid was provided, validate it
  if (traffic) {
    validateEid(traffic);
  }

  return input;
};

export {
  calcPurchaseEid,
  calcNewVsRenewal,
  emptyStringForUndefined,
  filterLogType,
  getPackageIds,
  transformForTealium,
  transformForTealiumV2,
  unnestProperties,
  validateEid,
  validateElement
};

export {
  _transformCjValues
};
