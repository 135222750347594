import { setCookie } from '@tcc/shared/src/helpers/cookie';

import cmdLogPageRequest from './methods/cmdLogPageRequest';
import cmdLogPageEvent from './methods/cmdLogPageEvent';
import cmdLog from './methods/cmdLog';
import cmdGetTrackingValues from './methods/cmdGetTrackingValues';
import cmdLogPagePerf from './methods/cmdLogPagePerf';
import cmdLogImpression from './methods/cmdLogImpression';

// Do init for the module
setCookie('traffic', '', 20, '/');

// Methods used for the traffic data layer process are published through here
export { cmdLogPageRequest, cmdLogPageEvent, cmdLog, cmdGetTrackingValues, cmdLogPagePerf, cmdLogImpression };
