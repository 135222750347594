
import { merge, map, stringify } from '@tcc/shared/src/helpers/object';
import { getClickEventData as getBrowserClickEventData } from '@tcc/shared/src/helpers/browser';

import { createEventProperties, addEventProperties, getEventSvcProperties, createTData } from '../../../interfaces/traffic/eventProperties';
import { sendEvent } from '@tcc/shared/src/integrations/eventService/eventService';

import { encodeProperties } from '@tcc/shared/src/helpers/url';
import { setCookie } from '@tcc/shared/src/helpers/cookie';

import { sendPageEvent as sendGAPageEvent } from '../../../integrations/google/ga';

import { setReferCookie } from '@tcc/shared/src/helpers/visit';
import AbstractEventHandler from '../../csp/handlers/abstractEventHandler';
import { CLICK_SCHEMA_ID, ELEMENT_ACTION_SCHEMA_ID, IMPRESSION_SCHEMA_ID, TRAFFIC_EVENT_SCHEMA_ID } from '../../../utils/schemaConstants';
import { buildElementEventData, buildTrafficData } from '../../../utils/eventBusUtils';
import { isValidEid } from '../../../utils/eidUtils';
import { buildMessage } from '../../../utils/logUtils';
import { error } from '@tcc/shared/src/helpers/logger';
import { GENERIC_CONVERSION_PREFIX } from '../../../utils/eidUtils';

// All eventtypes are shortened down to 10 characters.
// Therefore, the below events should not exceed 10 characters.
// https://confluence.godaddy.com/display/CKPT/Non+Interactive+Events
const nonInteractiveEvents = [
  'impression', 'experiment', 'load', 'apicall',
  'split-test', 'redux', 'change', 'custom',
  'impress', 'blur', 'view', 'applog', 'show',
  'success', 'perf', 'loaded', 'clicktaleu', 'notify',
  'clicktale', 'consent', 'timing'];

class AddEventHandler extends AbstractEventHandler {
  process() {
    const eid = this.pageEvent.get('e_id');
    if (process.env.NODE_ENV !== 'production' && !isValidEid(eid)) {
      error(buildMessage('invalid_eid_warning', eid));
    }

    super.process({
      EVENT_SVC: () => { this._handleEventSvc(); },
      GA: () => { this._handleGA(); }
    });
  }

  _handleEventSvc() {
    sendEvent(
      merge(this.pageEvent.getProperties(), getEventSvcProperties(this.internal.eventId)),
      '/pageEvents.aspx');
  }

  _handleGA() {
    if (this.pageEvent.get('e_id').startsWith('hivemind'))
      return;

    // Send 'Event' record to Google Analytics
    sendGAPageEvent(
      this.pageEvent.get('e_id'),
      merge(this.tData.getProperties(), this.pageEvent.getProperties(), this.internal),
      this.nonInteraction);
  }

  preProcess() {
    this.tData = createTData(this.data);

    // Some pages might still be using the deprecated property "properties" as custom properties
    this.tData.merge(this.data.ALL.properties);

    this.pageEvent = createEventProperties('page.event', this.tData);
    addEventProperties(this.pageEvent, this.data.ALL.type);

    // We will only accept the dom_element and dom_event in the v1 schema
    this.pageEvent.merge(getBrowserClickEventData(this.data.ALL.dom_element, this.data.ALL.dom_event));

    // in the v2 schema, the click event data is supplied as a property
    this.pageEvent.merge({
      href: this.data.ALL.href,
      tcode: this.data.ALL.tcode,
      tms: this.data.ALL.tms,
      ci: this.data.ALL.ci
    });

    // override dom element attributes with those passed in.
    if (this.data.ALL.eid) {
      this.pageEvent.set('e_id', this.data.ALL.eid);
    }

    // Use event_label provided from schema (if provided) before one provided in tdata (if provided)
    const eventLabel = this.data.ALL.event_label || this.tData.getProperties().event_label;
    if (eventLabel) {
      this.pageEvent.set('event_label', eventLabel);
    }

    // Set refer cookie based on whether this was an interactive event
    this._setNonInteraction();
  }

  _setNonInteraction() {
    this.nonInteraction = nonInteractiveEvents.indexOf(this.data.ALL.type.toLowerCase()) !== -1;
    if (!this.nonInteraction) {
      setReferCookie(
        this.pageEvent.get('e_id'),
        this.pageEvent.get('usrin'),
        this.pageEvent.get('tcode'),
        this.pageEvent.get('ci'),
        this.pageEvent.get('tms'),
        // Client-side session identifier.
        this.pageEvent.get('corrid'));
    }
  }

  _setReferCookie(eid, usrin, tcode, ci, tms, corrid) {
    const dataProps = {};
    // delete refer cookie
    setCookie('tcc_refer', '', -1, '/');
    if (eid) {
      dataProps.refer_e_id = eid;
    }
    if (usrin) {
      dataProps.refer_usrin = usrin;
    }
    if (tcode) {
      dataProps.refer_tcode = tcode;
    }
    if (ci) {
      dataProps.refer_ci = ci;
    }
    if (tms) {
      dataProps.refer_tms = tms;
    }
    if (corrid) {
      dataProps.refer_corrid = corrid;
    }
    map(dataProps, () => {
      const encodedProperties = encodeProperties(dataProps);
      setCookie('tcc_refer', stringify(encodedProperties, '&', '='), 5, '/');
      return true;
    });
  }

  /**
   * @param { string } eventCategory 'click', 'impression', 'blur'
   * @returns { string } 'click', 'impresion', 'elementAction'
   */
  _getSchemaId(eventCategory) {
    if (eventCategory === 'click') return CLICK_SCHEMA_ID;

    if (eventCategory === 'impression') return IMPRESSION_SCHEMA_ID;

    if (['blur', 'drag', 'focus', 'hover', 'load', 'scroll'].includes(eventCategory)) return ELEMENT_ACTION_SCHEMA_ID;

    // The generic "fallback" event / schemaId
    return TRAFFIC_EVENT_SCHEMA_ID;
  }

  _getEvents() {
    // Pull eid from property bag, which may have been populated from DOM
    const eid = this.pageEvent.get('e_id');
    const eventCategory = this.data.ALL.type.toLowerCase();

    const schemaId = this._getSchemaId(eventCategory);
    const customProperties = this.tData.getProperties();

    return [{
      schemaId,
      data: schemaId !== TRAFFIC_EVENT_SCHEMA_ID ?
        // This handler bifurcates addClick, addImpression, and addElementAction.
        // For all of those, the schema supports an element object.
        buildElementEventData(this.internal.eventId, eid, eventCategory, customProperties) :
        // Else, we only include the common property bags + eid
        {
          traffic: buildTrafficData({ customProperties, eid, eventCategory }),
          producerEventId: this.internal.eventId
        }
    }];
  }

  _hasConversion() {
    return this.pageEvent.get('e_id').startsWith(GENERIC_CONVERSION_PREFIX);
  }
}

export default AddEventHandler;
