import { findCookie } from './cookie';
import { sha256 } from './crypto';

let _authInfoToken = {};

const _authRealms = [
  'idp',
  'jomax'
];

const _authRealmIdMap = {
  idp: 'info_shopperId',
  jomax: 'info_accountName'
};

const _isTokenEmpty = (token) => {
  return Object.keys(token).length < 1;
};

// Fetch the info_token for a particular realm from the browser
const _getInfoTokenClaims = (realm) => {
  let claims = {};
  const cookieName = 'info_' + realm;
  const cookie = findCookie(cookieName);
  if (cookie) {
    try {
      claims = JSON.parse(cookie);
    } catch (err) {} // eslint-disable-line no-empty
  }
  return claims;
};

/**
 *
 * @description - Finds/updates _authInfoToken with the newest auth token.
 *               If none are found, the _authInfoToken variable is cleared.
 *
 */
const updateAuthTokens = () => {
  let token = {};
  for (let i = 0; i < _authRealms.length; i++) {
    const realm = _authRealms[i];
    const infoToken = _getInfoTokenClaims(realm);

    // Update the info token if its newer or not currently defined
    if (!_isTokenEmpty(infoToken) &&
    (_isTokenEmpty(token) || token.iat < infoToken.iat)) {
      token = infoToken;
    }
  }

  _authInfoToken = token;
};

const isAuthenticated = () => {
  return !_isTokenEmpty(_authInfoToken);
};

// boolean value of whether the authenticated
// user is in a delegation scenario
const isDelegated = () => {
  if (!isAuthenticated()) {
    return false;
  }

  const authType = _authInfoToken.auth;
  return authType && authType !== 'basic';
};

// Boolean value of whether an employee token is found
// on the browser or the newest token is an employee
// delegation scenario
const isEmployee = () => {
  if (!isAuthenticated()) {
    return false;
  }

  const tokenType = _authInfoToken.typ;
  const authType = _authInfoToken.auth;
  // Check if the latest token is for jomax
  if (tokenType && tokenType === 'jomax') {
    return true;
  }

  // Check if the latest token is an employee impersonation
  if (authType && authType !== 'basic' && authType.charAt(0) === 'e') {
    return true;
  }

  // Check if there is a jomax token on the browser
  const jomaxToken = _getInfoTokenClaims('jomax');
  return !_isTokenEmpty(jomaxToken);
};

/**
 * @param { object } currentClaims - The current claims we are parsing for the delegate. E.g. {
 *  typ: 'idp',
 *  auth: 'e2s',
 *  del: {
 *    typ: 'jomax',
 *    info_accountName: 'FakeEmployeeId'
 *  },
 *  e2s: {
 *    type: 'idp',
 *    info_cid: 'FakeParentCustomerId'
 *  }
 * }
 * @param { string } delType - The type of delegation token to find. E.g. jomax or idp
 * @param { boolean } isRoot - It checks if we have traced inside del object atleast once.
 *                                     Defaults to True as we enter from root.
 * @description Given an authentication claim object and a delegation type,
 *              this function will recursively find the associated claims for
 *              that specific delegation type. This logic will only work for tokens
 *              with a max depth of 2 (i.e. e2s2s) given that we're looking ahead
 *              via claims.del.del to see if the 2nd parent matches the token type.
 * @returns { object } The matching delegate's claims. If a matching delegate of the
 *                     specified type is not found, then undefined is returned.
 * @example output {
 *    typ: 'jomax',
 *    info_accountName: 'FakeEmployeeId'
 *   }
 * @example 2
 * // for currentClaims :{
  typ: 'idp',
  auth: 'e2s2s',
  del: {
    typ: 'idp',
    auth: 'e2s',
    del: {
      typ: 'jomax',
      info_accountName: 'FakeEmployeeId',
      auth: 'basic'
    },
    e2s: {
      info_shopperId: '141234119',
      info_cid: 'FakeParentCustomerId'
    }
  },
  e2s2s: {
    info_shopperId: '225376367',
    info_cid: 'FakeCustomerId'
  }
};
* it looping inside the first block and to reach the furthest del block, then it gets control to second if,
* which sends the block of e2s currentclaims.
* returns {
      info_shopperId: '141234119',
      info_cid: 'FakeParentCustomerId'
    }
 */
const _getDelegationClaims = (currentClaims, delType, isRoot = true) => {
  if (currentClaims.del) {
    if (currentClaims.del.typ === delType || currentClaims.del.del) {
      isRoot = false;
      return _getDelegationClaims(currentClaims.del, delType, isRoot);
    }
    if (delType === currentClaims.typ && !isRoot)
      return currentClaims[currentClaims.auth];
  }
  if (delType === currentClaims.typ && !isRoot) // this works for s2s
    return currentClaims;
};

const _cryptOrUndefined = (encrypt, value) => {
  if (!encrypt) {
    // If we're not encrypting... just return the value
    return value;
  }

  if (value) {
    // Return encrypted value
    return sha256(value);
  }
};

// Fetch a claim from a delegation token
// (This will parse the token recursively)
const getDelegateClaim = (claim, encrypt, delType) => {
  if (isAuthenticated() && _authInfoToken.auth !== 'basic') {
    const delegationClaims = _getDelegationClaims(_authInfoToken, delType);
    if (delegationClaims) {
      return _cryptOrUndefined(encrypt, delegationClaims[claim]);
    }
  }
};

// Fetch the jomax (employee) username from the current token
const getDelegateEmployeeId = (encrypt) => {
  return getDelegateClaim(_authRealmIdMap.jomax, encrypt, 'jomax');
};

// Returns the employee id if a jomax token is found
// If the newest token is an employee delegation token,
// the employee id will be returned.
const getEmployeeId = (encrypt) => {
  if (isAuthenticated()) {
    const tokenType = _authInfoToken.typ;
    const authType = _authInfoToken.auth;
    const idClaimName = _authRealmIdMap.jomax;

    // Check if the latest token is an employee impersonation
    if (authType && authType !== 'basic' && authType.charAt(0) === 'e') {
      return getDelegateEmployeeId(encrypt);
    }

    // Check if the latest token is for jomax
    if (tokenType && tokenType === 'jomax') {
      return _cryptOrUndefined(encrypt, _authInfoToken[idClaimName]);
    }

    // Check if there is a jomax token on the browser
    const jomaxToken = _getInfoTokenClaims('jomax');
    if (!_isTokenEmpty(jomaxToken)) {
      return _cryptOrUndefined(encrypt, jomaxToken[idClaimName]);
    }
  }
};

// Search an auth token for a particular claim
const _getInfoTokenClaim = (tokenClaims, claim, type) => {
  const tokenType = tokenClaims.typ;
  if (typeof (type) !== 'undefined' &&
    tokenType &&
    tokenType !== type) {
    return;
  }

  let rVal;
  const authType = tokenClaims.auth;
  // If this is a delegation token, search
  // sub claims recursively
  if (authType && authType !== 'basic') {
    const subClaims = tokenClaims[authType];
    rVal = _getInfoTokenClaim(subClaims, claim, type);
  } else {
    rVal = tokenClaims[claim];
  }

  return rVal;
};

// Get the customer id for the user who is delegated into
// the current user.
const getDelegateCustomerId = (encrypt) => {
  return getDelegateClaim('info_cid', encrypt, 'idp');
};

// Get the customer id from the newest auth token
const getCustomerId = (encrypt) => {
  if (isAuthenticated()) {
    // Search the info token for the customer id claim
    return _cryptOrUndefined(encrypt, _getInfoTokenClaim(_authInfoToken, 'info_cid'));
  }
};

// Fetches the appropriate ID for the newest auth token.
// If it's a delegation token, the subordinate's
// (child's) ID will be returned.
const getUserId = (encrypt) => {
  if (isAuthenticated()) {
    const tokenType = _authInfoToken.typ;

    // For the auth type (idp vs pass vs jomax), lookup the
    // name of the claim that holds the customer id
    const claimToLookup = _authRealmIdMap[tokenType];

    // Search the info token for the customer id claim
    return _cryptOrUndefined(encrypt, _getInfoTokenClaim(_authInfoToken, claimToLookup));
  }
};

// Fetches the shopper ID for the newest auth token.
// If it's a delegation token, the subordinate's
// (child's) ID will be returned.
const getShopperId = (encrypt) => {
  if (isAuthenticated()) {
    const tokenType = _authInfoToken.typ;
    if (tokenType === 'idp') {
      return _cryptOrUndefined(encrypt, _getInfoTokenClaim(_authInfoToken, 'info_shopperId'));
    }
  }
};

// Fetches the claim for the newest auth token.
// If it's a delegation token, the subordinate's
// (child's) claim will be searched.
const getClaimFromInfoToken = (claimName) => {
  if (isAuthenticated()) {
    return _getInfoTokenClaim(_authInfoToken, claimName);
  }
};

// First tries to fetch the fpid for the newest auth tokken.
// If not found, fallback/fetch the fpid from the brand cookie
const getFederationPartnerId = () => {
  if (isAuthenticated()) {
    let fpid = _getInfoTokenClaim(_authInfoToken, 'fpid');
    if (!fpid) {
      const tokenType = _authInfoToken.typ;
      if (typeof tokenType !== 'undefined') {
        fpid = findCookie(`brand_${tokenType}`);
      }
    }
    return fpid;
  }
};

// Fetches the realm for the newest auth token.
// Regardless of whether it's a delegate token,
// the subordinate (child) user's realm is on the root.
// https://confluence.godaddy.com/display/AUTH/Token+Claims
const getUserRealm = () => {
  if (isAuthenticated()) {
    return _authInfoToken.typ;
  }
};

export {
  getClaimFromInfoToken,
  getDelegateCustomerId,
  getDelegateEmployeeId,
  getEmployeeId,
  getFederationPartnerId,
  getCustomerId,
  getUserRealm,
  getShopperId,
  isAuthenticated,
  isDelegated,
  isEmployee,
  updateAuthTokens,
  getUserId
};

// Private exports for testing
export {
  _getInfoTokenClaims,
  _getInfoTokenClaim,
  _getDelegationClaims,
  _cryptOrUndefined
};
