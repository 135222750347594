import { map } from './object';

class PropertySet {

  constructor(properties) {
    this.properties = properties || {};
  }

  clear() {
    this.properties = {};
  }

  get(name) {
    if (this.properties.hasOwnProperty(name)) {
      return this.properties[name];
    }
  }

  set(name, value) {
    this.properties[name] = value;
  }

  isSet(name) {
    if (this.properties.hasOwnProperty(name)) {
      return true;
    }
  }

  delete(name) {
    delete this.properties[name];
  }

  getProperties(fnIgnore) {
    const returnProps = {};
    let _fnIgnore = () => {
      return false;
    };
    if (!fnIgnore && typeof fnIgnore === 'function') {
      _fnIgnore = fnIgnore;
    }
    map(this.properties, (key, value) => {
      if (_fnIgnore(key)) {
        return;
      }
      returnProps[key] = value;
    });
    return returnProps;
  }

  merge(properties) {
    let mergeObject = properties;
    if (mergeObject instanceof PropertySet) {
      mergeObject = properties.getProperties();
    }
    map(mergeObject, (key, value) => {
      this.set(key, value);
    });
  }
}

export default PropertySet;
