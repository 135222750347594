import { createEventProperties, addEventProperties, createTData, getEventSvcProperties } from '../../../interfaces/traffic/eventProperties';
import { sendEvent } from '@tcc/shared/src/integrations/eventService/eventService';
import { merge } from '@tcc/shared/src/helpers/object';

import AbstractEventHandler from '../../csp/handlers/abstractEventHandler';
import { RIGOR_PAGE_NAV_SCHEMA_ID, RIGOR_PAGE_NAV_DELAYED_SCHEMA_ID, TRAFFIC_EVENT_SCHEMA_ID } from '../../../utils/schemaConstants';
import config from '@tcc/shared/src/helpers/config';
import { isArray } from '@tcc/shared/src/helpers/array';
import { buildPageLoadDelayedEvent, buildPageLoadEvent, buildTrafficData } from '../../../utils/eventBusUtils';
import { NAVIGATION_TIMING_EID } from '../../../utils/eidConstants';

const _stringifyArray = (arry, valueKey) => {
  var result = '';

  arry.forEach(record => {
    const value = record[valueKey];
    // A value of 0 is valid / should not be discarded
    if (record.name && typeof value !== 'undefined') {
      result += record.name + ',' + value + '^';
    }
  });

  // remove trailing ^
  return result.substring(0, result.length - 1);
};

class AddPerfHandler extends AbstractEventHandler {

  process() {
    super.process({
      EVENT_SVC: () => { this._handleEventSvc(); }
    });
  }

  _handleEventSvc() {
    sendEvent(
      merge(this.pageEvent.getProperties(), getEventSvcProperties(this.internal.eventId)),
      '/b.aspx');
  }

  _getApiKey() {
    return config.get('tcc.eventBusRigorApiKey');
  }

  _getEvents(input) {
    if (input.properties) {
      if (input.properties.navigationStart) {
        return [{
          schemaId: RIGOR_PAGE_NAV_SCHEMA_ID,
          data: buildPageLoadEvent(
            this.internal.eventId,
            input.properties,
            input.custom_properties,
            input.properties.nav_type)
        }];
      } else if (input.properties.timeToInteractive) {
        return [{
          schemaId: RIGOR_PAGE_NAV_DELAYED_SCHEMA_ID,
          data: buildPageLoadDelayedEvent(
            this.internal.eventId,
            input.properties)
        }];
      }
    } else if (input.custom_properties) {
      // Some teams began using this interface to ship custom properties w/o any metrics.
      // For CSP, we're mapping those to the generic traffic event w/ our static nav timing EID from Traffic.
      return [{
        schemaId: TRAFFIC_EVENT_SCHEMA_ID,
        data: {
          traffic: buildTrafficData({
            customProperties: input.custom_properties,
            eid: NAVIGATION_TIMING_EID,
            eventCategory: input.type
          }),
          producerEventId: this.internal.eventId
        }
      }];
    }
  }

  preProcess() {
    this.pageEvent = createEventProperties('page.log', createTData(this.data));

    addEventProperties(this.pageEvent, this.data.ALL.type);

    // Hard vs Soft navigation (normal page request vs virtual page request)
    this.pageEvent.set('nav_type', this.data.ALL.nav_type);

    // This handler is reused by multiple perf interfaces and is kept
    // around for legacy functionality (primarily SPAs)
    const properties = this.data.ALL.properties;
    if (properties) {
      this.pageEvent.merge(properties);

      // Stringify for event service GET call
      const marks = properties.marks;
      if (isArray(marks)) {
        this.pageEvent.set('marks', _stringifyArray(marks, 'startTime'));
      }

      // Stringify for event service GET call
      const measures = properties.measures;
      if (isArray(measures)) {
        this.pageEvent.set('measures', _stringifyArray(measures, 'duration'));
      }
    }
  }
}

export default AddPerfHandler;
