import { error } from '@tcc/shared/src/helpers/logger';
import SchemaHandler from '@tcc/shared/src/schema/schemaHandler';

class GetVariantForExperimentHandler extends SchemaHandler { // eslint-disable-line id-length

  process() {
    super.process({
      ALL: (input) => { this._handleSplitio(input); }
    });
  }

  _handleSplitio(input) {
    if (process.env.NODE_ENV !== 'production') {
      error('The `get_variant_for_experiment` Traffic interface has been deprecated. Please migrate to the Hivemind SDK.', 'https://github.secureserver.net/hivemind/hivemind-onboarding#hivemind-sdks');
    }
    input.callback('control');
  }
}

export default GetVariantForExperimentHandler;
