import PropertySet from './property';

// The object generated using this class should be
// initialized with any default configuration values
// as soon as your application starts. You can then
// use that object to fetch any global configurations.

const config = new PropertySet();

export default config;
