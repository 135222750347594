import { getWindow } from '@tcc/shared/src/helpers/browser';

const cmdLogPageRequest = (pathname) => {
  getWindow()._expDataLayer.push({
    schema: 'add_page_request',
    version: 'v1',
    data: {
      virtual_path: pathname } });
};

export default cmdLogPageRequest;
