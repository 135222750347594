import * as logger from '../helpers/logger';
import { createGuid } from '../helpers/guid';
import DataLayer from '../helpers/dataLayer';
import { processPageLevelProperties } from '../helpers/configManager';

const dataLayerNames = ['_expDataLayer', '_signalsDataLayer'];

class SignalsDataLayer extends DataLayer {

  constructor({ schemaHelper, pageViewSchema, autoPageViewDisabled, onProcess, immediateSchemas }) {
    super(dataLayerNames);
    this.schemaHelper = schemaHelper;
    this.pageViewSchema = pageViewSchema;
    this.autoPageViewDisabled = autoPageViewDisabled;
    this.onProcess = onProcess;
    this.immediateSchemas = immediateSchemas;
    this._initEvents();
  }

  push(cmd) {
    if (this.immediateSchemas.includes(cmd.schema)) {
      return this.process(cmd);
    }
    return super.push(cmd);
  }

  _initEvents() {
    let firePageView = !this.autoPageViewDisabled;

    for (let i = this.asyncCommands.length - 1; i >= 0; i--) {
      const record = this.asyncCommands[i];
      // If the user has pushed a hard page view event (i.e. it doesn't have a virtual path),
      // then return / don't send our automatic hard page view event.
      if (record.schema === this.pageViewSchema && (!record.data || !record.data.virtual_path)) {
        firePageView = false;
      }

      // Process and remove config records (so that they're not reprocessed)
      if (record.schema === 'set_config')
        this.process(...this.asyncCommands.splice(i, 1));
    }

    // Fire auto page view
    if (firePageView)
      this.process({ schema: this.pageViewSchema });
  }

  process(cmd) {
    super.pushLog(cmd);

    try {
      // parse the command array
      const inputData = cmd.data;
      const schema = cmd.schema;
      const type = cmd.type;
      const version = cmd.version || 'v1';
      const sinks = cmd.sinks;

      const internal = {
        eventId: createGuid()
      };

      this.onProcess && this.onProcess();

      // Parse/update page level properties on each event
      processPageLevelProperties();

      // If a list of sinks is provided, it will override the sinks in our schema
      this.schemaHelper.handleSchema(schema, type, version, inputData, internal, sinks);
    } catch (error) {
      const errArray = ['Unable to process', cmd, '\n\n'];
      logger.error.apply(logger, errArray.concat(error));
    }
  }
}

export default SignalsDataLayer;

