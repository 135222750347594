import { merge } from '@tcc/shared/src/helpers/object';
import { buildElementEventData } from '../../../utils/eventBusUtils';
import { ELEMENT_ACTION_SCHEMA_ID } from '../../../utils/schemaConstants';
import AbstractEventHandler from './abstractEventHandler';

class AddElementAction extends AbstractEventHandler {
  _getSchemaId() {
    return ELEMENT_ACTION_SCHEMA_ID;
  }
  /**
 * @param { object } input { element: { action: 'blur' }}
 * @returns { string } 'blur'
 * @description This function will return the action from the provided element.
 * This function is overriden by child classes to define their own impl. of the action.
 */
  _getActionType(input) {
    return input.element.action;
  }

  // Override method (Optional)
  _getEvents(input, producerEventId) {
    const traffic = input.traffic || {};

    return [{
      schemaId: this._getSchemaId(),
      data: merge(input, buildElementEventData(
        producerEventId || this.internal.eventId,
        traffic.eid,
        this._getActionType(input),
        traffic.customProperties))
    }];
  }

  /**
   * @param { string } type 'click'
   * @param { object } element { area, product, section, widget }
   * @param { string } eid this.is.a.fake.eid
   * @returns { string } an eid
   * @description Returns the EID if set. Else, converts the element
   * into a valid EID.
   */
  _buildLegacyEid(type, element, eid = `${element.area}.${element.product}.${element.section}.${element.widget}.${type}`) {
    return eid;
  }

  _handleLegacy(input) {
    const element = input.element || {};
    const traffic = input.traffic || {};

    // Send to the generic add_event handler with corresponding type
    const type = element.action;
    this.schemaHelper.handleSchema(
      'add_event',
      undefined,
      'v2',
      {
        eid: this._buildLegacyEid(type, element, traffic.eid),
        properties: traffic && traffic.customProperties,
        type
      },
      this.internal);
  }

  _transformLegacyPromo(type, element, { eid, customProperties } = {}, { id, name, creative, position } = {}) {
    return {
      id,
      name,
      creative_name: creative,
      creative_slot: position,
      type,
      properties: customProperties,
      eid: this._buildLegacyEid(type, element, eid)
    };
  }

  _isPromo(input) {
    return input.promotion && Object.keys(input.promotion).length > 0;
  }
}

export default AddElementAction;
