import { debug } from '@tcc/shared/src/helpers/logger';
import config from '@tcc/shared/src/helpers/config';

import { getWindow, getDocument, formatPageName } from '@tcc/shared/src/helpers/browser';
import { getCookieValues, setCookie } from '@tcc/shared/src/helpers/cookie';
import { getCleanQueryString } from '@tcc/shared/src/helpers/url';
import { merge, map } from '@tcc/shared/src/helpers/object';
import AbstractEventHandler from '../../csp/handlers/abstractEventHandler';

import page from '@tcc/shared/src/interfaces/traffic/eventPageProperties';

import { sendEvent } from '@tcc/shared/src/integrations/eventService/eventService';
import { createEventProperties, getEventSvcProperties, createTData } from '../../../interfaces/traffic/eventProperties';
import { sendPageView as sendGaPageView } from '../../../integrations/google/ga';
import { initTracing } from '@tcc/shared/src/helpers/page';
import VisitHelperTcc from '../../../helpers/visitHelperTcc';
import { PAGE_VIEW_SCHEMA_ID } from '../../../utils/schemaConstants';
import { buildTrafficData } from '../../../utils/eventBusUtils';
import { processConfigProperties } from '@tcc/shared/src/helpers/configManager';
import { parseBoolean } from '@tcc/shared/src/utils/stringUtils';

let _lastPathName;
let _pageComponents;
const _visitHelper = new VisitHelperTcc();

const _getComponents = () => {
  const components = config.get('components');
  if (_pageComponents !== components) {
    // Save for next page view
    _pageComponents = components;

    // Build result for CSP structure
    const result = [];
    map(_pageComponents, (name, version) => {
      result.push({
        name,
        version
      });
    });
    return result;
  }
};

class AddPageViewHandler extends AbstractEventHandler {
  process() {
    super.process({
      EVENT_SVC: () => { this._handleEventSvc(); },
      GA: (input) => { this._handleGA(input); }
    });
  }

  _handleEventSvc() {
    sendEvent(
      merge(page.getProperties(), getEventSvcProperties(this.internal.eventId)),
      '/image.aspx');
  }

  _handleGA(input) {
    // Send 'Page View' record to Google Analytics
    sendGaPageView(input.virtual_path || input.virtualPath, merge(this.tData.getProperties(), page.getProperties(), this.internal));
  }

  _getViewProperties() {
    const v = {};
    const win = getWindow();
    const doc = getDocument();

    v.browx = win.innerWidth ? win.innerWidth : doc.body.offsetWidth;
    v.browy = win.innerHeight ? win.innerHeight : doc.body.offsetHeight;
    v.resx = win.screen.width;
    v.resy = win.screen.height;
    v.cdepth = win.screen.colorDepth;

    return v;
  }

  _getEvents() {
    return [{
      schemaId: PAGE_VIEW_SCHEMA_ID,
      data: {
        forensics: {
          traceIdAdopted: parseBoolean(page.get('traced')),
          components: _getComponents()
        },
        traffic: buildTrafficData({ customProperties: this.tData.getProperties() }),
        producerEventId: this.internal.eventId
      }
    }];
  }

  preProcess() {
    // Build tData object
    this.tData = createTData(this.data);

    // snake_case == traffic interface (deprecated), camelCase == csp interface (new)
    let pathname = this.data.ALL.virtual_path || this.data.ALL.virtualPath;

    const isVirtualPage = typeof pathname !== 'undefined';

    if (isVirtualPage) {
      page.set('virtual_path', pathname);
    } else {
      // Send just the pathname to Traffic unless a virtual page was specified
      pathname = getWindow().location.pathname;
    }

    if (process.env.NODE_ENV !== 'production') {
      debug('cmd: LogPageRequest invoked with path', pathname);
    }

    if (_lastPathName === pathname) {
      throw 'cmd: LogPageRequest duplicate page request detected, ignoring duplicate path';
    }
    _lastPathName = pathname;

    if (isVirtualPage)
      processConfigProperties();

    // fetch page count from cookie
    let pageCount = _visitHelper.getVisitInfo().pageCount;

    // increment by 1 and update cookie
    pageCount += 1;
    _visitHelper.setPageCount(pageCount);

    // Add generic event properties
    page.merge(createEventProperties('page.request', this.tData));

    page.set('page', formatPageName(pathname));
    page.set('hw', getWindow().history.length);

    // merge properties from browser view stats
    page.merge(this._getViewProperties());

    // get query string from URL
    const qs = getCleanQueryString();
    if (qs) {
      page.set('querystring', qs);
    }

    // Only set traceId if there is a value. This will allow for
    // SPAs to reuse the hard page load's traceId until we begin
    // updating the traceId on virtual route changes
    const traceId = page.get('trace_id');
    if (!traceId) {
      page.merge(initTracing());
    }

    page.merge(getCookieValues('tcc_refer'));
    setCookie('tcc_refer', '', -1, '/');
  }
}

const _resetLastPath = () => {
  _lastPathName = undefined;
};

export default AddPageViewHandler;

export {
  _resetLastPath
};
