import { merge } from '@tcc/shared/src/helpers/object';
import { validateEid } from '@tcc/shared/src/interfaces/interfaceUtils';
import { sendEcommerceEvent as sendGaEcommerceEvent } from '../../../integrations/google/ga';
import {
  createEventProperties,
  createTData
} from '../eventProperties';
import { buildElementEventData } from '../../../utils/eventBusUtils';
import AbstractEventHandler from '../../csp/handlers/abstractEventHandler';
import { createGuid } from '@tcc/shared/src/helpers/guid';

class AddPromotionHandler extends AbstractEventHandler {
  process() {
    super.process({
      EVENT_SVC: (input) => {
        this._handleEventSvc(input);
      },
      GA: (input) => {
        this._handleGA(input);
      }
    });
  }

  preProcess() {
    if (this.schemaType === 'click') {
      this.eid = 'traffic.tcc.instrumentation.internal_promotion.click';
      this.event_category = 'internal_promotion_click';
      this.gaType = 'select_content';
    } else if (this.schemaType === 'impression') {
      this.eid = 'traffic.tcc.instrumentation.internal_promotion.impression';
      this.event_category = 'internal_promotion_impression';
      this.gaType = 'view_promotion';
    }
    this.eventProps = createEventProperties('promotion');
    this.tData = createTData(this.data);
  }

  _handleGA(input) {
    const filterMap = merge(
      this.tData.getProperties(),
      this.eventProps.getProperties(),
      this.internal
    );
    const gaData = merge(input, {
      eid: this.eid,
      event_category: this.event_category
    });
    sendGaEcommerceEvent(this.gaType, gaData, filterMap);
  }

  _logTrafficEvent(type, input) {
    try {
      // will throw an exception if the eid is not provided as a parameter or
      // within the provided dom_element
      validateEid(input);

      const addEventVersion = this.schemaVersion === 'v1' ? 'v1' : 'v2';
      // call the 'add_event' schema
      this.schemaHelper.handleSchema(
        'add_event',
        undefined,
        addEventVersion,
        merge({ type }, input),
        this.internal,
        // only send traffic as the output group, else another GA hit would be fired
        ['EVENT_SVC']
      );
    } catch (error) {
      // No need to do anything here. For 'add_promotion', eid is not required.
      // We simply won't send the record to GA if the eid is not provided.
    }
  }

  _handleEventSvc(input) {
    if (input.promotions) {
      for (let i = 0; i < input.promotions.length; i++) {
        this._logTrafficEvent(this.schemaType, input.promotions[i]);
      }
    } else {
      this._logTrafficEvent(this.schemaType, input);
    }
  }

  /**
   * @param { string } producerEventId The unique identifier for this event
   * @param { array } output The array
   * @param { string } eventType The type of event as either set in or passed to our interfaces.
   *                             E.g. 'impression', 'click'. Note that in Traffic/CSP/GA this field
   *                             is called 'eventCategory'
   * @param { string } id 'test-id'
   * @param { string } eid 'test.promotion.button.eid'
   * @param { string } name 'test-name'
   * @param { string } creative_name 'test-creative-name'
   * @param { string } creative_slot 'test-creative-slot'
   * @description attempts to build the CSP event and adds it
   * to the output array only if the building was successful
   * @returns { undefined }
   */
  _addCspEvent(producerEventId, output, eventType, { id, eid, name, creative_name, creative_slot }) { // eslint-disable-line camelcase
    const data = buildElementEventData(producerEventId, eid, eventType, this.tData.getProperties());

    output.push({
      schemaId: `urn:shared:user:event:/data-platform/signals/${eventType}/v1`,
      data: merge(data, {
        promotion: {
          id: id,
          name: name,
          creative: creative_name, // eslint-disable-line camelcase
          position: creative_slot // eslint-disable-line camelcase
        }
      })
    });
  }

  /**
   * @param { object } event { id: string, name: string, creative_name: string, creative_slot: string } or
   * { impressions: [{id: string, name: string, creative_name: string, creative_slot: string}] }
   * @description If the event is a click the event object is going to be an object. If the event is a impression, the
   * event object is going to has a nested array called "impressions" where it has nested objects with the same
   * properties as the click event
   * @link To see click and impression schemas:
   * https://github.com/gdcorp-dna/bus-registry/tree/master/schemas/stage/shared/user/event/data-platform/signals
   * @returns { array } output
   * @example output [{
   *  schemaId: string,
   *  data: {
   *    element: {
   *      area: string,
   *      product: string,
   *      section: string,
   *      widget: string,
   *    },
   *    promotion: {
   *      id: string,
   *      name: string,
   *      creative: string,
   *      position: string,
   *    }
   *  }
   * }]
   */
  _getEvents(event) {
    const output = [];
    const eventType = this.schemaType;

    if (event.hasOwnProperty('promotions')) {
      event.promotions.map((impression) => {
        // Promo impressions in CSP will get their own eventId and
        // will not be joinable with Traffic/GA.
        this._addCspEvent(createGuid(), output, eventType, impression);
      });
    } else {
      this._addCspEvent(this.internal.eventId, output, eventType, event);
    }

    return output;
  }
}

export default AddPromotionHandler;
